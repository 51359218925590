import axios from "axios";
import React, { useEffect, useState } from "react";
import { ICourse, HomeworkUser, ILesson } from "src/interface";
import { useParams, Link, useLocation } from "react-router-dom";
import moment from "moment";
import "moment/locale/ru";
moment.locale("ru");
import "./HomeworksByLessons.scss";
import { authHeader } from "src/utils/authHeader";

export const HomeworksByLessons = () => {
  document.title = "Курсы | EasyENT";
  const [course, setCourse] = useState<ICourse>();
  const [allhomeworks, SetAllhomeworks] = useState<HomeworkUser[]>();
  const [lesson, SetLesson] = useState<any>();
  const params = useParams();
  const location: any = useLocation();
  const [sortOption, setSortOption] = useState("best");
  useEffect(() => {
    axios
      .get("/api/v1/courses/" + params.course_id, { headers: authHeader() })
      .then((response) => {
        setCourse(response.data);
      });
    
    axios
      .get("/api/v1/lessons/" + params.id, { headers: authHeader() })
      .then((response) => {
        SetLesson(response.data);
      });
      
  }, []);
  
  useEffect(() => {
    if(lesson){
      const newdate = lesson.start_date.slice(0,10);
      axios
      .get(
        "/api/v1/homework/by_lesson/" + params.id + "?in_feed_date=" + newdate + "&status_student=true",
        { headers: authHeader() }
      )
      .then((response) => {
        SetAllhomeworks(response.data);
      });
    }
  }, [lesson]);

  function getDeadLineText(lesson: ILesson) {
    if (lesson?.end_date) {
      const endDate = moment(lesson?.end_date);
      const deadLine = moment(endDate).add(3, "days");
      const todaysDate = moment();
      const daysDifference = deadLine.diff(todaysDate, "days");

      if (daysDifference < 0) {
        return ``;
      } else if (daysDifference === 0) {
        return "Сегодня последний день";
      } else if (daysDifference > 0 && daysDifference < 4) {
        return `Осталось ${Math.abs(daysDifference)} дн.`;
      } else {
        return ``;
      }
    }
  }
  const handleSortChange = (option:any) => {
    setSortOption(option);
  };
  const sortedHomeworks = () => {
    if (!allhomeworks) return [];
  
    return [...allhomeworks].sort((a, b) => {
      // Handle undefined values by treating them as 0
      const correctA = a.homework_is_correct ?? 0;
      const correctB = b.homework_is_correct ?? 0;
  
      if (sortOption === "best") {
        return correctB - correctA; // Descending order
      } else if (sortOption === "worst") {
        return correctA - correctB; // Ascending order
      } else if (sortOption === "zero") {
        return a.homework_pass === 0 ? -1 : 1; // Zero results come first
      }
      return 0;
    });
  };
  return (
    <div>
      <ul className="breadcrumbs">
        <li>
          <Link to="/teacher">Мои курсы </Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <Link to={course ? `/teacher/course_${course.id}` : ""}>
            {course ? course.name : "загрузка"}
          </Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <span>{lesson ? lesson.name : "загрузка"}</span>
        </li>
      </ul>
      <h1 className="title">{course ? course.name : "загрузка"}</h1>
      <div className="sort-part">
        <div className="sort-part__area">
          <input
            type="radio"
            name="sort"
            id="sort-best"
            value="best"
            checked={sortOption === "best"}
            onChange={() => handleSortChange("best")}
          />
          <label htmlFor="sort-best">Сортировка по Лучшим</label>
        </div>
        <div className="sort-part__area">
          <input
            type="radio"
            name="sort"
            id="sort-worst"
            value="worst"
            checked={sortOption === "worst"}
            onChange={() => handleSortChange("worst")}
          />
          <label htmlFor="sort-worst">Сортировка по Худшим</label>
        </div>
        <div className="sort-part__area">
          <input
            type="radio"
            name="sort"
            value="zero"
            id="sort-zero"
            checked={sortOption === "zero"}
            onChange={() => handleSortChange("zero")}
          />
          <label htmlFor="sort-zero">ДЗ нету</label>
        </div>
      </div>


      <div className="select-part">
        {getDeadLineText(lesson) && (
          <span className="lesson-card__deadline">
            {getDeadLineText(lesson)}
          </span>
        )}
      </div>
      <table className="table">
        <tbody>
          <tr>
            <th style={{ width: "30px" }}>Id</th>
            <th style={{ width: "180px" }}>Фио</th>
            <th style={{ width: "140px" }}>Ник в телеге</th>
            <th style={{ width: "140px" }}>Оценка домашки</th>
            <th style={{ width: "130px" }}>Управление</th>
          </tr>
          {sortedHomeworks().map((user) => (
            <tr key={user.user_id}>
              <td style={{ width: "40px" }}>{user.user_id}</td>
              <td style={{ width: "220px" }}>{user.full_name}</td>
              <td style={{ width: "120px" }}><a target="_blank" href={"https://t.me/" + user.zoom_account}>{user.zoom_account}</a></td>
              <td style={{ width: "180px" }}>
                {user.homework_pass !== 0
                  ? `${user.homework_is_correct} из ${user.question_count}`
                  : "Отсуствует"}
              </td>
              <td style={{ width: "130px" }}>
                <div className="item-control">
                  <Link
                    to={`${location?.pathname}/${user?.user_id}`}
                    state={{ course, lesson, user }}
                  >
                    Подробнее
                  </Link>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
