import React, { useState, useEffect, useRef } from "react";

import "./Login.scss";
import { Link } from "react-router-dom";
import { login, getUser } from "../../service/asyncThunk";
import { UserSlice } from "../../service/reducers/UserSlice";
import { useAppDispatch } from "../../hooks/redux";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { namespaces } from "src/i18n/i18n.constants";
export const Login = () => {
  const { updateUser, setToken } = UserSlice.actions;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(namespaces.pages.hello);
  const InputPassword = useRef<HTMLInputElement>(null);
  const InputEmail = useRef<HTMLInputElement>(null);
  const [showPassword, setShowPassword] = useState(false);
  const [searchParams, ] = useSearchParams();

  useEffect(() => {
    if (InputPassword.current != null) {
      if (showPassword) {
        InputPassword.current.type = "text";
      } else {
        InputPassword.current.type = "password";
      }
    }
  }, [showPassword]);

  const onLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(
      login({
        email: InputEmail?.current?.value,
        password: InputPassword?.current?.value,
        client_id: searchParams.get('tg_uuid'),
      })
    ).then((res: any) => {
      if (!res?.error?.message) {
        dispatch(setToken(res));
        dispatch(getUser()).then((sres: any) => {
          if (!res?.sres?.message) {
            dispatch(updateUser(sres.payload));
            if (sres.payload.is_superuser) {
              navigate("/admin/");
            } else if (sres.payload.is_teacher) {
              navigate("/teacher");
            } else {
              navigate("/my-courses");
            }
          }
        });
      }
    });
  };

  // const onLoginAnotherService = (service: string) => {
  //   window.open(`/api/v1/login/${service}`, "_self");
  // };

  const onShowPassword = () => {
    setShowPassword((s) => !s);
  };

  return (
    <div className="login-container">
      <img src="/assets/cabinet-login.png" />
      <form className="login-form" onSubmit={onLogin}>
        <h1>{t("login.Вход_в_личный_кабинет", { ns: namespaces.common })}</h1>
        <input
          className="login-form__input"
          type="email"
          name="email"
          ref={InputEmail}
          defaultValue=""
          required
          placeholder="Электронная почта"
        />

        <div className="password">
          <input
            className="login-form__input"
            type="password"
            ref={InputPassword}
            name="password"
            defaultValue=""
            placeholder="Пароль"
            required
          />
          <i
            className="fa-solid fa-eye"
            onClick={onShowPassword}
            style={{ color: showPassword ? "#3561FF" : "#a8acb9" }}
          ></i>
        </div>

        <button className="btn btn-primary btn-flex" type="submit">
          {t("login.Войти", { ns: namespaces.common })}
          <i className="fa-solid fa-right-to-bracket"></i>
        </button>
        <Link to="/forget">{t("login.Забыли_пароль", { ns: namespaces.common })} </Link>
        {/* <div className="another-services">
          <div className="another-services__title">
            Или войдите с помощью других сервисов
          </div>
          <div className="another-services__buttons">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onLoginAnotherService("vk")}
            >
              <i className="fa-brands fa-vk"></i>
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onLoginAnotherService("google")}
            >
              <i className="fa-brands fa-google" />
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => onLoginAnotherService("facebook")}
            >
              <i className="fa-brands fa-facebook" />
            </button>
          </div>
        </div> */}
        <span className="register">
        {t("login.Еще_нет_профиля", { ns: namespaces.common })}  <Link to="/register">{t("login.Зарегистрируйтесь", { ns: namespaces.common })}</Link>
        </span>
      </form>
    </div>
  );
};
