import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { authHeader } from "src/utils/authHeader";
import Select from 'react-select';
export const TeacherTestsPage = () => {
  document.title = "Тесты | EasyENT";
  const [tests, setTests] = useState<any>([]);
  const [filteredTests, setFilteredTests] = useState<any>([]);
  const [year, setYear] = useState<string>("");
  const [month, setMonth] = useState<string>("");
  const [subjectId, setSubjectId] = useState<string>("");
  const [newTest, setNewTest] = useState<any>({
    subject_id:1,
    lang:"ru",
    write_date: "2024-01-22T11:36:21.623Z",
  }); 
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const value = element.value;
    // const name = element.name;
    setNewTest((s:any) => ({ ...s, ['write_date']: value }));
    console.log(newTest);
  };
  const [subjectValue, setSubjectValue] = useState<any>({ value: 1, label: 'История Казахстана' });
  useEffect(() => {
    axios
      .get("/api/v1/tests/tests/?skip=0&limit=150", { headers: authHeader() })
      .then((response) => {
        setTests(response.data);
        setFilteredTests(response.data); // Initialize filtered tests
      });
  }, []);


  const handleFilter = () => {
    const filtered = tests.filter((test: any) => {
      const testDate = new Date(test.write_date);
      const testYear = testDate.getFullYear().toString();
      const testMonth = (testDate.getMonth() + 1).toString().padStart(2, "0"); // Month as two digits
      return (
        (year ? testYear === year : true) &&
        (month ? testMonth === month : true) &&
        (subjectId ? test.subject.id.toString() === subjectId : true)
      );
    });
    setFilteredTests(filtered);
  };

  // Apply filter whenever filter criteria change
  useEffect(() => {
    handleFilter();
  }, [year, month, subjectId]);
  const subjects = [
    { value: 1, label: "История Казахстана" }, 
    { value: 5, label: "Мат.Грамотность" },
    { value: 10, label: "Математика" },
    { value: 23, label: "Физика" },
    { value: 29, label: "Химия" },
    { value: 48, label: "Биология" },
    { value: 91, label: "География" },
    { value: 110, label: "Английский язык" },
    { value: 100, label: "Всемирная история" },
    { value: 121, label: "Информатика" },
  ];
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    axios
      .post("/api/v1/tests/tests/", newTest, { headers: authHeader() })
      .then((response) => {
        if (response.status == 200) {
          window.location.reload();
          alert('Тест добавлен')
        }
      })
      .catch((error) => {
        alert(error.response.data);
      });
  }
  return (
    <div className="my-course">
      <h1 className="title">Тесты</h1>
      <div className="white-row box">
        <select className="white-row__select" value={year} onChange={(e) => setYear(e.target.value)}>
          <option value="">Выберите год</option>
          <option value="2024">2024</option>
          <option value="2023">2023</option>
          <option value="2022">2022</option>
        </select>
        <select className="white-row__select" value={month} onChange={(e) => setMonth(e.target.value)}>
          <option value="">Месяц</option>
          <option value="01">Январь</option>
          <option value="02">Февраль</option>
          <option value="03">Март</option>
          <option value="04">Апрель</option>
          <option value="05">Май</option>
          <option value="06">Июнь</option>
          <option value="07">Июль</option>
          <option value="08">Август</option>
          <option value="09">Сентябрь</option>
          <option value="10">Октябрь</option>
          <option value="11">Ноябрь</option>
          <option value="12">Декабрь</option>
        </select>
        <select className="white-row__select" value={subjectId} onChange={(e) => setSubjectId(e.target.value)}>
          <option value="">Предмет</option>
          {subjects.map((subject) => (
            <option key={subject.value} value={subject.value}>
              {subject.label}
            </option>
          ))}
        </select>
      </div>
      <form onSubmit={onSubmit} className="add-dz box">
        <div className="form-row form-row--main">
          <label><b>Предмет:</b></label>
          <Select 
            defaultValue={subjectValue}
            options={subjects}
            onChange={(option: any) => {
            if(  option ) setNewTest((item:any) => ({...item,
                  subject_id: option.value
                })
              ) ;
                setSubjectValue(option);
                }
            }  
            name="display_name"
          />
        </div>
        <div className="form-row form-row--main">
          <label><b>Дата:</b></label>
          <input
              required
              name="start_date"
              type="datetime-local"
              onChange={onChange}
            />
        </div>
        
        <button className="btn btn-primary" type="submit">Добавить тест</button>
      </form>    
 
      <table className="table">
        <tbody>
          <tr>
            <th>Id</th>
            <th>Предмет</th>
            <th>Язык</th>
            <th>Дата</th>
            <th>Управление</th>
          </tr>
          {filteredTests.map((test: any) => (
            <tr key={test.id}>
              <td>{test.id}</td>
              <td>{test.subject.title}</td>
              <td>{test.lang}</td>
              <td>{test.write_date}</td>
              <td>
                <Link className="btn btn-primary btn-flex" to={"/teacher/tests/" + test.id}>
                  <i className="fa-solid fa-pen"></i>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
