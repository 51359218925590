import axios from "axios";
import React, { useEffect, useState, useCallback } from "react";
import { ICourse, HomeworkUser, ILesson } from "src/interface";
import { useParams, Link, useLocation } from "react-router-dom";
import moment from "moment";
import "moment/locale/ru";
moment.locale("ru");
import { authHeader } from "src/utils/authHeader";
import { Popconfirm } from "src/components";
import "./AdminHomeworksByLessons.scss";

export const AdminHomeworksByLessons = () => {
  document.title = "Курсы | EasyENT";
  const [course, setCourse] = useState<ICourse>();
  const [allhomeworks, SetAllhomeworks] = useState<HomeworkUser[]>();
  const [lesson, SetLesson] = useState<any>();
  const params = useParams();
  const location: any = useLocation();
  const [visible, setVisible] = useState(false);
  const [studentId, setStudentId] = useState<number>();
  const [sortOption, setSortOption] = useState("best");
  const showPopconfirm = useCallback(() => {
    setVisible((value: boolean) => !value);
  }, []);

  function getAllHomeworks() {
    axios
      .get(
        "/api/v1/homework/by_lesson/" + params.id,
        { headers: authHeader() }
      )
      .then((response) => {
        SetAllhomeworks(response.data);
      });
  }

  function removeHomework() {
    axios
      .delete(`/api/v1/homework/by_lesson_and_user/${params.id}-${studentId}`, {
        headers: authHeader(),
      })
      .then(() => {
        showPopconfirm();
        getAllHomeworks();
      });
  }

  useEffect(() => {
    axios
      .get("/api/v1/courses/" + params.course_id, { headers: authHeader() })
      .then((response) => {
        setCourse(response.data);
      });
    
    axios
      .get("/api/v1/lessons/" + params.id, { headers: authHeader() })
      .then((response) => {
        SetLesson(response.data);
      });
      
  }, []);
  
  useEffect(() => {
    if(lesson){
      const newdate = lesson.start_date.slice(0,10);
      axios
      .get(
        "/api/v1/homework/by_lesson/" + params.id + "?in_feed_date=" + newdate + "&status_student=true",
        { headers: authHeader() }
      )
      .then((response) => {
        SetAllhomeworks(response.data);
      });
    }
  }, [lesson]);
  const handleSortChange = (option:any) => {
    setSortOption(option);
  };
  const sortedHomeworks = () => {
    if (!allhomeworks) return [];
  
    return [...allhomeworks].sort((a, b) => {
      // Handle undefined values by treating them as 0
      const correctA = a.homework_is_correct ?? 0;
      const correctB = b.homework_is_correct ?? 0;
  
      if (sortOption === "best") {
        return correctB - correctA; // Descending order
      } else if (sortOption === "worst") {
        return correctA - correctB; // Ascending order
      } else if (sortOption === "zero") {
        return a.homework_pass === 0 ? -1 : 1; // Zero results come first
      }
      return 0;
    });
  };
  
  function getDeadLineText(lesson: ILesson) {
    if (lesson?.end_date) {
      const endDate = moment(lesson?.end_date);
      const deadLine = moment(endDate).add(3, "days");
      const todaysDate = moment();
      const daysDifference = deadLine.diff(todaysDate, "days");

      if (daysDifference < 0) {
        return ``;
      } else if (daysDifference === 0) {
        return "Сегодня последний день";
      } else if (daysDifference > 0 && daysDifference < 4) {
        return `Осталось ${Math.abs(daysDifference)} дн.`;
      } else {
        return ``;
      }
    }
  }

  return (
    <div>
      <ul className="breadcrumbs">
        <li>
          <Link to="/admin/courses">Мои курсы</Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <Link to={course ? `/admin/courses/course/${course.id}` : ""}>
            {course ? course.name : "загрузка"}
          </Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <span>{lesson ? lesson.name : "загрузка"}</span>
        </li>
      </ul>
      <h1 className="title">{course ? course.name : "загрузка"}</h1>
      <div className="sort-part">
        <div className="sort-part__area">
          <input
            type="radio"
            name="sort"
            id="sort-best"
            value="best"
            checked={sortOption === "best"}
            onChange={() => handleSortChange("best")}
          />
          <label htmlFor="sort-best">Сортировка по Лучшим</label>
        </div>
        <div className="sort-part__area">
          <input
            type="radio"
            name="sort"
            id="sort-worst"
            value="worst"
            checked={sortOption === "worst"}
            onChange={() => handleSortChange("worst")}
          />
          <label htmlFor="sort-worst">Сортировка по Худшим</label>
        </div>
        <div className="sort-part__area">
          <input
            type="radio"
            name="sort"
            value="zero"
            id="sort-zero"
            checked={sortOption === "zero"}
            onChange={() => handleSortChange("zero")}
          />
          <label htmlFor="sort-zero">ДЗ нету</label>
        </div>
      </div>

      <div className="select-part">
        {getDeadLineText(lesson) && (
          <span className="lesson-card__deadline">
            {getDeadLineText(lesson)}
          </span>
        )}
      </div>
      <table className="table">
        <tbody>
          <tr>
            <th style={{ width: "30px" }}>Id</th>
            <th style={{ width: "210px" }}>Фио</th>
            <th style={{ width: "140px" }}>Ник в телеге</th>
            <th style={{ width: "140px" }}>Оценка домашки</th>
            <th style={{ width: "130px" }}>Управление</th>
          </tr>
          {sortedHomeworks().map((user) => (
            <tr key={user.user_id}>
              <td style={{ width: "40px" }}>{user.user_id}</td>
              <td style={{ width: "200px" }}>{user.full_name}</td>
              <td style={{ width: "120px" }}>{user.zoom_account}</td>
              <td style={{ width: "180px" }}>
                {user.homework_pass !== 0
                  ? `${user.homework_is_correct} из ${user.question_count}`
                  : "Отсуствует"}
              </td>
              <td style={{ width: "130px" }}>
                <div className="item-control">
                  <Link
                    to={`${location?.pathname}/${user?.user_id}`}
                    state={{ course, lesson, user }}
                  >
                    Подробнее
                  </Link>
                  <Link
                    to="#"
                    style={{ marginLeft: 8 }}
                    onClick={() => {
                      showPopconfirm();
                      setStudentId(user?.user_id);
                    }}
                  >
                    Удалить
                  </Link>
                </div>
              </td>
            </tr>
          ))}

        </tbody>
      </table>
      <Popconfirm
        visible={visible}
        header="Вы действительно хотите удалить домашнее задание?"
        footer={
          <>
            <button
              onClick={removeHomework}
              className="popconfirm__btn-danger"
              style={{ marginRight: 10 }}
            >
              Удалить
            </button>
            <button
              type="button"
              onClick={showPopconfirm}
              className="popconfirm__btn-secondary"
            >
              Закрыть окно
              <i className="fa-solid fa-xmark"></i>
            </button>
          </>
        }
      />
    </div>
  );
};
