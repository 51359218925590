import axios from "axios";
import React, { useEffect, useState } from "react";
import { AdminUser } from "src/interface";
import { authHeader } from "src/utils/authHeader";
import { useParams } from "react-router-dom";

interface HomeworkData {
  course_id: number;
  name: string;
  question_count: number;
  homework_is_correct_count: number;
  homework_is_correct_percent: number;
}

interface ProgressData {
  progress: number;
  completed_homework: string;
  average_rating: number;
}

export const AdminUserPerfomance = () => {
  document.title = "Успеваемость ученика";
  const params = useParams<{ id: string }>();
  const [user, setUser] = useState<AdminUser>();
  const [homeworkData, setHomeworkData] = useState<HomeworkData[]>([]);
  const [progressData, setProgressData] = useState<{ [key: string]: ProgressData }>({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserData = axios.get(`/api/v1/users/${params.id}/`, { headers: authHeader() });
    const fetchProgressData = axios.get(`/api/v1/users/userprogress`, {
      params: { user_id: params.id },
      headers: authHeader(),
    });

    Promise.all([fetchUserData, fetchProgressData])
      .then(([userResponse, progressResponse]) => {
        setUser(userResponse.data);
        setProgressData(progressResponse.data);

        const userFeeds = userResponse.data.user_feeds as { course_id: number }[];
        const uniqueCourseIds = Array.from(new Set(userFeeds.map((course) => course.course_id)));

        const homeworkRequests = uniqueCourseIds.map((course_id) =>
          axios
            .get<HomeworkData[]>(`/api/v1/homework/overall_lessons_homeworks_by_user_id`, {
              params: {
                start_date: "2024-09-10",
                end_date: "2024-12-10",
                course_id,
                user_id: params.id,
              },
              headers: authHeader(),
            })
            .then((response) => response.data)
        );

        return Promise.all(homeworkRequests);
      })
      .then((data) => {
        setHomeworkData(data.flat());
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [params.id]);

  const averageHomeworkPercent = homeworkData.length
    ? homeworkData.reduce((sum, course) => sum + course.homework_is_correct_percent, 0) / homeworkData.length
    : 0;

  return (
    <div className="box">
      <h1 className="title">Успеваемость ученика: {user ? user.full_name : ""}</h1>
      <p className="title__sub">Общая процент выполнения дз: {averageHomeworkPercent.toFixed(2)}%</p>
      <ul className="anal-ul">
        {loading ? (
          <p>Loading...</p>
        ) : (
          homeworkData.map((course) => {
            const courseProgress = progressData[course.course_id.toString()];
            return (
              <li key={course.course_id}>
                <div className="lesson-analize">
                  <span className="lesson-analize__heading">{course.name}</span>
                  <div className="lesson-analize__row">
                    <p>Количество вопросов: {course.question_count}</p>
                  </div>
                  <div className="lesson-analize__row">
                    <p>Правильные ответы: {course.homework_is_correct_count}</p>
                  </div>
                  <div className="lesson-analize__row">
                    <p>Процент правильных ответов: {course.homework_is_correct_percent.toFixed(2)}%</p>
                  </div>
                  {courseProgress && (
                    <div className="lesson-analize__row">
                      <p>Выполнено дз: {courseProgress.completed_homework}</p>
                    </div>
                  )}
                </div>
              </li>
            );
          })
        )}
      </ul>
    </div>
  );
};
