import React, { useEffect } from "react";
import { useNavigate} from "react-router-dom";
import { Login, Header, Footer } from "src/components";
import { useAppSelector } from "src/hooks/redux";


import "./LoginPage.scss";

export const LoginPage = () => {
  document.title = "Войти | EasyENT";
  const navigate = useNavigate();
  // const [searchParams, setSearchParams] = useSearchParams();
  const isLoggedIn = useAppSelector((store) => store.user.loggedIn);

  useEffect(() => {
    if (isLoggedIn) {
      // const searchParams = useSearchParams();
      // if (searchParams.get('tg_uuid') != undefined) {
      //   const uuid = searchParams.get('tg_uuid');
      //   axios.post('/api/v1/telegram/bind_user', {
      //     uuid: uuid
      //   }, { headers: authHeader()})
      //   .then((user) => {
      //     localStorage.setItem("user", JSON.stringify(user.data));
      //   })
      // }
      // localStorage.setItem("user", JSON.stringify(store.user.data));
      navigate("/my-courses");
    }
  }, []);

  return (
    <div className="app-container">
      <Header isEmpty={true} />
      <div className="content-wrapper">
        <Login />
      </div>
      <Footer />
    </div>
  );
};
export default LoginPage;