import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { OrderShort, Pricelist } from "src/interface";
import { authHeader } from "src/utils/authHeader";
import { toast } from "react-toastify";
import "./ModalUserUpdate.scss";

export const ModalOrderUpdate: FC<{ active: boolean, setActive: (value: boolean) => void, orderId: number, orderUsername:string, userPricelist: number }> =
  ({ active, setActive, orderId, userPricelist, orderUsername }) => {
    const [order, setOrder] = useState<OrderShort | null>(null);
    const [pricelist, setPricelist] = useState<Pricelist[]>([]);
    const [userPricelistFull, setUserPricelistFull] = useState<Pricelist | null>();
    useEffect(() => {
      axios
        .get("/api/v1/order/" + orderId, { headers: authHeader() })
        .then((response) => setOrder(response.data));
    }, [orderId]);
    useEffect(() => {
      axios
        .get("/api/v1/pricelist/?skip=0&limit=100", { headers: authHeader() })
        .then((response) => setPricelist(response.data))
        .catch((error) => console.error("Failed to fetch pricelists:", error));
    }, []);


    useEffect(() => {
      const foundPricelist = pricelist.find(item => item.id === +userPricelist);
      if (foundPricelist) {
        setUserPricelistFull(foundPricelist);
        
      } 
    }, [pricelist, userPricelist]);
    const handlePositionChange = (index: number, field: string, value: any) => {
      setOrder((prevOrder) => {
        if (!prevOrder) return prevOrder;
        const updatedPositions = [...prevOrder.positions];
        updatedPositions[index] = { ...updatedPositions[index], [field]: value };
        return { ...prevOrder, positions: updatedPositions };
      });
    };
    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const updatedOrder = {
        total_amount: order?.total_amount || 0,
        package: order?.package || "standard",
      };
      axios
        .put("/api/v1/order/" + orderId, updatedOrder, { headers: authHeader() })
        .then((response) => {
          console.log(response.data);
          if (response.status === 200) {
            toast.success("Платежка создана!");
            setActive(false);
          }
        })
        .catch(error => {
          alert(error.response.data);
          window.location.reload();
        });
    };
    return (
      <div className={active ? "modal-photo active" : "modal-photo"} onClick={() => setActive(false)}>
        <div className="modal-content" onClick={e => e.stopPropagation()}>
          <h1 className="modal-content__heading">Обновить платежку</h1>
          {order !== null ? 
            <form onSubmit={onSubmit} className="popup-form">
              <div className="edit-form-row">
                <label className="edit-form-row__label">Фио ученика:</label>
                <p>{orderUsername}</p>
              </div>  
              <div className="edit-form-row edit-form-row--small">
                <label className="edit-form-row__label">Сумма:</label>
                <div className="flex">
                  <input
                    className="input edit-form-row__priceinput"
                    placeholder="Сумма"
                    name="total_amount"
                    type="number"
                    defaultValue={order.total_amount}
                    onChange={(e) => setOrder({ ...order, total_amount: parseFloat(e.target.value) })}
                  />
                  <label className="radio-label">
                    <input className="radio-label__input" type="checkbox"></input> 
                    Добавить 10% скидку
                  </label>
                </div>
              </div>
              <div className="edit-form-row">
                <label className="edit-form-row__label">Пакет:</label>
                <label className="radio-label">
                  <input
                    type="radio"
                    name="package"
                    checked={order.package === "standard"}
                    value="standard"
                    onChange={() => setOrder((prev) => prev && { ...prev, package: "standard" })}
                  />
                  Про
                </label>
                <label className="radio-label">
                  <input
                    type="radio"
                    name="package"
                    checked={order.package === "premium"}
                    value="premium"
                    onChange={() => setOrder((prev) => prev && { ...prev, package: "premium" })}
                  />
                  Премиум
                </label>
              </div>

              <div className="edit-form-row">
                <label className="edit-form-row__label">Курсы:</label>
                {order.positions.map((position, index) => (
                  <label key={index} className="position-row">
                    <input
                      id="course_id"
                      type="checkbox"
                      checked={true} 
                      onChange={() => handlePositionChange(index, 'course_id', position.course_id)}
                    />
                    {position.course.name} 
                  </label>
                ))}
              </div>
              <div className="edit-form-row">
                <p className="flex">Прайслист: <label className="edit-form-row__label">{userPricelistFull?.name}</label></p>
                <ul className="pricelist-ul">
                {userPricelistFull?.positions
                  .filter(position => position.course_count <= 4) // Filter for course_count <= 5
                  .sort((a, b) => a.course_count - b.course_count) // Sort by course_count in ascending order
                  .map((position, i) => (
                    <li key={i}>{position.course_count} пр: {position.price}</li>
                  ))}
                </ul>
              </div>  
              <div className="btn-group">
                <button className="btn btn-primary" type="submit">
                  Обновить <i className="fa-solid fa-floppy-disk"></i>
                </button>
              </div>
            </form>
          : "Загрузка..."}
        </div>
      </div>
    );
  };
