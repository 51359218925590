import axios from "axios";
import React, { useState, FC } from "react";
import { authHeader } from "src/utils/authHeader";
import "./ModalPenalty.scss";

export const ModalPenalty: FC<{active:boolean; setActive:(value:boolean) => void; penaltyid:number  }> = 
  ({active, setActive, penaltyid}) => {
    const [cancelReason, setCancelReason] = useState<{ cancel_reason: string }>({
      cancel_reason: ""
    });
    const cancelPenalty = () => {
      if(cancelReason){
        axios
        .post(`/api/v1/penalty/${penaltyid}/cancel`, cancelReason, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.status == 200) {
            alert('Пенальи отменен');
            setActive(false);
          }
        })
      }
    }
    console.log(cancelReason);
  return (
    <div className={active ? "modal active" : "modal"} onClick={()=> setActive(false)}>
      <div className="modal__content" onClick={e => e.stopPropagation()}>
        <div className="modal-info">
          <span className="modal-content__heading">Отмена пенальти</span>
          <input onChange={(e)=> setCancelReason({cancel_reason:e.target.value})} placeholder="Напишите причину" type="text"/>
          <button className="my-btn" onClick={()=> cancelPenalty()}>Отменить</button>
        </div>
        
        {/* <button onClick={()=> setActive(false)} className="my-btn">
          Закрыть окно
          <i className="fa-solid fa-xmark"></i>
        </button>  */}
      </div>
    </div>
  )
}
