import axios from "axios";
import React, { useEffect, useState } from "react";
import { TeacherItem, TeacherItemSkeleton } from "src/components";
import { ITeacherItem } from "src/interface";
import { authHeader } from "src/utils/authHeader";
import { useAppSelector } from "src/hooks/redux";
import "./TeachersPage.scss";

export const TeachersPage = () => {
  document.title = "Преподаватели | EasyENT";
  const [teachers, setTeachers] = useState<ITeacherItem[]>([]);
  const user = useAppSelector((store) => store.user.data);
  useEffect(() => {
    if (user) {
      axios
        .get("/api/v1/teacher/?lang=ru", { headers: authHeader() })
        .then((response) => setTeachers(response.data));
    }
    else {
      console.log('пусто')
    }  
  }, []);

  return (
    <div className="teachers-container">
      <h1 className="title">Преподаватели</h1>
      <div className="teachers-list">{teachers.length !== 0 ? (
        teachers.map((teacher) => 
          teacher.is_curator !== true ? <TeacherItem key={teacher.id} teacher={teacher} /> : ""
        )
      ) : (
        <>
          <TeacherItemSkeleton />
        </>
      )}</div>
    </div>
  );
};
