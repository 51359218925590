import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.scss?v=1.11";
import { LoginPage, AuthPage, PrivacyPage, TermsPage, RegisterPage, ForgetPassword, ForgetReturnPage, ResetPasswordPage } from "./pages";
import { ApprovalPage, RegNotificationPage } from "./pages_public";
import { setupStore } from "./service/store";
import App from "./App";
import { ToastContainer } from "react-toastify";

import "./i18n/i18n";
import "react-loading-skeleton/dist/skeleton.css";
import 'react-toastify/dist/ReactToastify.css';

const container = document.getElementById("root") as HTMLElement;
const root = ReactDOM.createRoot(container);
const store = setupStore();

root.render(
  <>
    <BrowserRouter>
      <Provider store={store}>
        <ToastContainer />
        <Routes>
          <Route path="*" element={<App />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/auth/:provider" element={<AuthPage />} />
          <Route path="/privacy" element={<PrivacyPage />} />
          <Route path="/terms" element={<TermsPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/forgetreturn" element={<ForgetReturnPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/forget" element={<ForgetPassword />} />
          <Route path="/verify" element={<ApprovalPage />} />
          <Route path="/notification" element={<RegNotificationPage />} />
        </Routes>
      </Provider>
    </BrowserRouter>
  </>
);
