import React, { FC } from "react";
import { IPenalty } from "src/interface";
import "./Penalty.scss";

// Modify the function to handle cancel reason logic
function calculateHeartLevels(package_feed: string | undefined, penalties: IPenalty[]) {
  // Initialize an array with heart levels
  let heartLevels = [1, 1, 1];
  if (package_feed === 'premium') {
    heartLevels = [1, 1, 1, 1, 1];  // Premium users get 5 hearts
  }

  // Filter out penalties that do not have a cancel reason
  const penaltiesWithoutCancelReason = penalties.filter(penalty => !penalty.cancel_reason);

  // Calculate the remaining heart levels based on penalties without a cancel reason
  const reduceByNumber = heartLevels.length - penaltiesWithoutCancelReason.length;

  // Reduce the heart levels accordingly
  if (reduceByNumber > 0) {
    heartLevels.splice(reduceByNumber);
  }

  return heartLevels;
}

// The component definition
export const Penalty: FC<{ package_feed: string | undefined, penalties: IPenalty[] }> = ({
  penalties, package_feed
}) => {
  return (
    <>
      <div>Система жизни:</div>
      {calculateHeartLevels(package_feed, penalties).reverse().map((heart, index) => (
        <img key={index} src={`/assets/courses/heart-1.png`} width="25px" />
      ))}
    </>
  );
};
