import React from "react";
import "./Footer.scss";

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__wrapper">
        <div className="footer__item footer__item--first">          
          <div className="footer-first">
            <img className="footer-first__img" src="/assets/svg/logo--footer.svg" />
            <p>© EasyENT, 2024</p> 
          </div>           
        </div>
        <div className="footer__item">
          <span className="footer__heading">О EasyENT</span>
          <a target="_blank" href="https://easyent.kz/app/webroot/pdf/oferta.pdf">Договор оферты</a>
        </div>
        <div className="footer__item">
          <span className="footer__heading">Контактные данные</span>
          <ul className="footer-cont">
            <li>
              <span>Телефон для связи</span>
              +7 708 555 01 42
            </li>
          </ul>    
        </div>
        <div className="footer__item">
          <span className="footer__heading">Мы в соц.сетях</span>
          <div className="footer-links">
            <a target="_blank" href="https://instagram.com/easyent_">
              <img src="/assets/svg/instagram.svg" />
            </a>
            <a target="_blank" href="https://t.me/ent_is_easy">
              <img src="/assets/svg/telegram.svg" />
            </a>
            <a target="_blank" href="http://wa.me/77085550142">
              <img src="/assets/svg/whatsapp.svg" />
            </a>  
          </div>
        </div>
      </div>
    </footer>
  );
};
