import axios from "axios";
import React, { useEffect, useState } from "react";
import { newOrder, orderStatus } from "src/interface";
import { Link } from "react-router-dom";
import Select from 'react-select';
import "./AdminOrderPage.scss";
import { authHeader } from "src/utils/authHeader";
import { ModalOrderUpdate } from "src/components";

export const AdminOrdersPage = () => {
  document.title = "Ордера | EasyENT";
  const [allorders, setAllorders] = useState<newOrder[]>([]);
  const [orderStatus, setOrderStatus] = useState<orderStatus>({
    value:"draft", label: 'На оплату'
  });
  const [dateOption, setDateOption] = useState<any>({
    value:"2024-04-11", label: '1 поток'
  });
  const orderoptions:orderStatus[] = [
    { value: 'all', label: 'Все' },
    { value: 'draft', label: 'На оплату' },
    { value: 'payed', label: 'Оплачено' },
  ];
  const dateoptions:orderStatus[] = [
    { value: '2024-04-11', label: '1 поток' },
  ];
  const [overallSum, setOverallSum] = useState<number>(0);
  const [overallLength, setOverallLength] = useState<number>(0);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const date1 = "2024-12-09";
        const date2 = "2024-12-11";

        const [response1, response2] = await Promise.all([
          axios.get(
            `/api/v1/order/?skip=0&limit=500&status=${orderStatus.value}&start_date=${date1}`,
            { headers: authHeader() }
          ),
          axios.get(
            `/api/v1/order/?skip=0&limit=500&status=${orderStatus.value}&start_date=${date2}`,
            { headers: authHeader() }
          ),
        ]);

        const combinedOrders = [...response1.data, ...response2.data];

        setAllorders(combinedOrders);

        const totalSum = combinedOrders.reduce(
          (sum: number, order: newOrder) => sum + order.total_amount,
          0
        );
        const totalLength = combinedOrders.length;

        setOverallSum(totalSum);
        setOverallLength(totalLength);
      } catch (error) {
        console.error("Failed to fetch orders:", error);
      }
    };

    fetchOrders();
  }, [orderStatus]);
  const formatNumber = (number: number) => new Intl.NumberFormat('ru-RU').format(number);
  const [orderUpdateModalActive, setOrderUpdateModalActive] = useState(false); 
  const [orderId, setOrderId] = useState<number>(0); 
  const [orderUserName, setOrderUserName] = useState<string>("");
  const [userPricelist, setUserPricelist] = useState<number>(0); 
  const openUpdateOrderModal = (userid: number, orderUserName:string, pricelist: number) => {
    setOrderId(userid);
    setOrderUserName(orderUserName);
    setUserPricelist(pricelist); 
    setOrderUpdateModalActive(true);           
  };
  return (
    <div>
      <h1 className="title">Платежки</h1>
      <div className="box top-div">
        <div className="select-part">
          <Link to="payment" className="btn btn-primary btn-inline">Выставить платежку</Link>
          <div className="select-part__item">
            <p>Поток</p>
            <Select
                value={dateOption}
                options={dateoptions}
                onChange={(option: any) => {
                if( option) setDateOption(option)
                  
                    }
                  } 
            />
          </div>
          <div className="select-part__item">
            <p>Вид оплаты</p>
            <Select
                value={orderStatus}
                options={orderoptions}
                onChange={(option: any) => {
                  if (option) setOrderStatus(option)
                }} 
              />    
          </div>
        </div>
        <div className="summary">
            <p>Общее количество заказов: {overallLength}</p>
            <p>Общая сумма: {formatNumber(overallSum)} KZT</p>
        </div>
      </div>
      
      <table className="table table-fontsize">
        <tbody>
        <tr>
          <th style={{width: '40px'}}>
            Id
          </th>
          <th style={{width: '45px'}}>
            uid
          </th>
          <th style={{width: '230px'}}>
            Фио
          </th>
          <th style={{width: '170px'}}>
            Номер
          </th>          
          <th style={{width: '200px'}}>
            Почта
          </th>
          <th style={{width: '110px'}}>
            Cтатус
          </th>
          <th style={{width: '110px'}}>
            Дата
          </th>
          <th style={{width: '80px'}}>
            Сумма
          </th>
          <th style={{width: '155px'}}>
            Управление
          </th>
        </tr>
        {allorders.length ?  allorders.map((order) => (
          <tr key={order.id}>
            <td style={{width: '40px'}}>
              {order.id}
            </td>
            <td style={{width: '45px'}}>
              {order.user.id}
            </td>
            <td style={{width: '230px'}}>
              {order.user.full_name}
            </td>
            <td style={{width: '170px'}}>
              {order.user.phone}
            </td>
            <td style={{width: '220px'}}>
              {order.user.email}
            </td>
            <td style={{width: '110px'}}>
              {order.status == "draft" ? "на оплату" : "оплачено"}
            </td>
            <td style={{width: '110px'}}>
              {order.start_date} 
            </td>
            <td style={{width: '90px'}}>
              {order.total_amount} 
            </td>
            <td style={{width: '155px'}}>
              <div className="item-control">
                <div onClick={() => openUpdateOrderModal(order.id, order.user.full_name, order.user.pricelist)}  className="item-control__item">
                  <i className="fa-solid fa-pen"></i> Обновить
                </div>
              </div>
            </td>
          </tr>
        )) : "Загрузка"}
        </tbody>
      </table>
      {orderUpdateModalActive && orderId !== null && (
        <ModalOrderUpdate
          active={orderUpdateModalActive}
          orderId={orderId}
          orderUsername={orderUserName}
          userPricelist={userPricelist}
          setActive={setOrderUpdateModalActive}
        />
      )}
    </div>
  );
};
