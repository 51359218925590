import axios from "axios";
import React, { useEffect, useState } from "react";
// import { MyCourseItem } from "src/components";
// import { useAppDispatch, useAppSelector } from "src/hooks/redux";
import {  AdminLesson } from "src/interface";
// import { useForm, Controller } from "react-hook-form";
// import { UserSlice } from "../../service/reducers/UserSlice";
import { useParams, Link } from "react-router-dom";
import { authHeader } from "src/utils/authHeader";

export const UpdateHomework = () => {
  document.title = "Дз правка | EasyENT";
  const params = useParams();
  // const [allCourses, setAllCourses] = useState<ICourse[]>([]);
  const [lesson, setLesson] = useState<AdminLesson>({});
  // const [updatelesson, setUpdatelesson] = useState({
  //   name: "string",
  //   start_date: "string",
  // });
  const [, setUploadPhoto] = useState({
    photofile: "string",
  });

  useEffect(() => {
    axios
      .get("/api/v1/lessons/" + params.lesson_id, { headers: authHeader() })
      .then((response) => {
        const myLesson = response.data;
        setLesson({
          id: myLesson.id,
          name: myLesson.name,
          start_date: myLesson.start_date,
          end_date: myLesson.end_date,
          zoom_link: myLesson.zoom_link,
          video_url: myLesson.video_url,
          conspected: myLesson.conspected,
          course_id: myLesson.course_id,
          course_name: myLesson.course.name,
        })
      });
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const value = element.value;
    const name = element.name;
    if (lesson) {
      setLesson((s) => ({ ...s, [name]: value }));
    }
  };
  const UploadPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formdata = new FormData();
    console.log("123");
    if (event.target.files?.length) {
      formdata.append("file", event.target.files[0]);
      axios
        .post("/api/v1/lessons/" + params.lesson_id + "/conspected", formdata, {
          headers: authHeader(),
        })
        .then(() => {
          setUploadPhoto({ ["photofile"]: event.target.value });
          window.location.reload();
        })
        .catch((error) => {
          alert(error.response.data);
        });
    }
  };

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    axios
      .put("/api/v1/lessons/" + params.lesson_id + "/", lesson, {
        headers: authHeader(),
      })
      .then((response) => {
        console.log(response.data);
        if (response.status == 200) {
          console.log(response);
          window.location.reload();
        }
      })
      .catch((error) => {
        alert(error.response.data);
      });
  };
  return (
    <div className="my-course">
      <ul className="breadcrumbs">
        <li>
          <Link to="/admin/courses">Мои курсы</Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <Link to={`/admin/courses/course/${lesson?.course_id}`}>{lesson?.course_name}</Link>
        </li>
        <li>
          <span>
            {lesson?.id && <i className="fa-solid fa-arrow-right"></i>}
          </span>
        </li>
        <li>
          <span>{lesson?.id && lesson?.name}</span>
        </li>
      </ul>
      <h1 className="title">Обновление уроков</h1>
      {lesson ? (
        <form onSubmit={onSubmit} className="add-dz">
          <div className="form-row">
            <label>Название:</label>
            <input
              onChange={onChange}
              name="name"
              value={"" + lesson.name}
              type="text"
            ></input>
          </div>
          <div className="form-row">
            <label>Start date:</label>
            <input
              onChange={onChange}
              name="start_date"
              value={"" + lesson.start_date}
              type="text"
            ></input>
          </div>
          <div className="form-row">
            <label>End date:</label>
            <input
              onChange={onChange}
              name="end_date"
              value={"" + lesson.end_date}
              type="text"
            ></input>
          </div>
          <div className="form-row">
            <label>Youtube ссылка:</label>
            <input
              onChange={onChange}
              name="zoom_link"
              value={lesson.zoom_link ? "" + lesson.zoom_link : ""}
              type="text"
            ></input>
          </div>
          <div className="form-row">
            <label>Embedded:</label>
            <input
              onChange={onChange}
              name="video_url"
              value={lesson.video_url ? "" + lesson.video_url : ""}
              type="text"
            ></input>
          </div>
          <div className="form-row">
            <label>Номер урока:</label>
            <input
              onChange={onChange}
              name="lesson_number"
              value={lesson.lesson_number}
              type="text"
            ></input>
          </div>
          <div className="form-row">
            <label>Рабочая тетрадь:</label>
            <div>
              {lesson.conspected ? (
                <div>
                  <a
                    className="form-row__conspect"
                    href={"https://app.easyent.kz" + lesson.conspected}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Ccылка
                  </a>
                  <input
                    onChange={UploadPhoto}
                    accept="application/pdf"
                    type="file"
                  ></input>
                </div>
              ) : (
                <input
                  onChange={UploadPhoto}
                  accept="application/pdf"
                  type="file"
                ></input>
              )}
            </div>
          </div>
          
          <button className="btn btn-primary" type="submit">
            Обновить
          </button>
        </form>
      ) : (
        <div>Пусто</div>
      )}
    </div>
  );
};
