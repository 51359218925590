import axios from "axios";
import React, { useEffect, useState } from "react";
import { IPenalty, ICourse } from "src/interface";
// import { Link } from "react-router-dom";
import "./TeacherPenalty.scss";
import Select from 'react-select';
import { authHeader } from "src/utils/authHeader";
import { ModalPenalty, ModalUserHomework } from "src/components";

export const TeacherPenalty = () => {
  document.title = "Тесты | EasyENT";
  const [penalties, setPenalties] = useState<IPenalty[]>([]);
  const [courses, setCourses] = useState<ICourse[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<number | null>(null);
  
  // Separate modal states
  const [penaltyModalActive, setPenaltyModalActive] = useState(false); // Penalty modal state
  const [homeworkModalActive, setHomeworkModalActive] = useState(false); // Homework modal state
  
  // Penalty modal-specific state
  const [currentPenaltyId, setCurrentPenaltyId] = useState<number | null>(null); 
  
  // Homework modal-specific state
  const [lessonNumber, setLessonNumber] = useState<number | null>(null); 
  const [studentId, setStudentId] = useState<number | null>(null); 

  useEffect(() => {
    axios
      .get("/api/v1/courses?lang=ru", { headers: authHeader() })
      .then((response) => setCourses(response.data));
  }, []);

  useEffect(() => {
    const url = selectedCourse == null 
      ? "/api/v1/penalty/?skip=0&limit=1000&last_month=false" 
      : `/api/v1/penalty/?skip=0&limit=1000&last_month=false&course_id=${selectedCourse}`;
    
    axios.get(url, { headers: authHeader() })
      .then((response) => setPenalties(response.data));
  }, [selectedCourse]);

  const findCourseName = (courseId: number) => {
    const course = courses.find((course) => course.id === courseId);
    return course ? course.name : "Unknown Course";
  };

  const openPenaltyModal = (penaltyId: number) => {
    setCurrentPenaltyId(penaltyId); 
    setPenaltyModalActive(true);           
  };

  const openHomeworkModal = (lessonId: number, studentId: number) => {
    setLessonNumber(lessonId); 
    setStudentId(studentId); 
    setHomeworkModalActive(true);           
  };

  return (
    <div className="my-course">
      <h1 className="title">Пенальти</h1>
      <div className="white-row box">
        <div className="white-div">
          <span>Курсы:</span>
          <Select
            options={[
              { value: null, label: "Все курсы" },
              ...courses.map((course) => ({
                value: course.id,
                label: course.name,
              })),
            ]}
            onChange={(option: any) => setSelectedCourse(option ? option.value : null)}
            isClearable
          />
        </div>
      </div>
      <table className="table">
        <tbody>
          <tr>
            <th style={{ width: '40px' }}>Id</th>
            <th style={{ width: '150px' }}>Курс</th>
            <th style={{ width: '90px' }}>Урок</th>
            <th style={{ width: '120px' }}>Статус</th>
            <th style={{ width: '80px' }}>Дата создания</th>
            <th style={{ width: '90px' }}>Пользователь</th>
          </tr>
          {penalties.map((penalty) => (
            <tr key={penalty.id}>
              <td style={{ width: '40px' }}>{penalty.id}</td>
              <td style={{ width: '150px' }}>{findCourseName(penalty.course_id)}</td>
              <td style={{ width: '90px' }}>
                <p>{penalty.lesson_id}</p>
                <div 
                  onClick={() => openHomeworkModal(penalty.lesson_id, penalty.user_id)} 
                  className="item-control__item item-control__item--change"
                >
                  Ссылка на дз
                </div>
              </td>
              <td style={{ width: '120px' }}>
                {penalty.cancel_reason ? 
                "" : 
                <>
                  <p>"Потеря жизни"</p>
                  <div 
                    onClick={() => openPenaltyModal(penalty.id)} 
                    className="item-control__item item-control__item--change"
                  >
                    Поменять
                  </div>
                </>
                }
              </td>
              <td style={{ width: '150px' }}>{(penalty.created_at + "").slice(0, 10)}</td>
              <td style={{ width: '90px' }}>
                {penalty.user ? penalty.user.full_name : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Penalty Modal */}
      {penaltyModalActive && currentPenaltyId !== null && (
        <ModalPenalty
          active={penaltyModalActive}
          penaltyid={currentPenaltyId} 
          setActive={setPenaltyModalActive}
        />
      )}

      {/* Homework Modal */}
      {homeworkModalActive && lessonNumber && studentId !== null && (
        <ModalUserHomework
          active={homeworkModalActive}
          lessonNumber={lessonNumber} 
          studentId={studentId} 
          setActive={setHomeworkModalActive}
        />
      )}
    </div>
  );
};
