import axios from "axios";
import React, { useEffect, useState } from "react";
import { ICourse} from "src/interface";
import { Link } from "react-router-dom";

import "./AdminCoursesPage.scss";
import { authHeader } from "src/utils/authHeader";

export const AdminCoursesPage = () => {
  document.title = "Курсы | EasyENT";
  const [allCourses, setAllCourses] = useState<ICourse[]>([]);
  useEffect(() => {
      axios
        .get("/api/v1/courses?lang=ru", { headers: authHeader()})
        .then((response) => setAllCourses(response.data));
  }, []);
 
  return (
    <div className="my-course">
      <h1 className="title">Курсы</h1>
      <table className="table">
        <tbody>
        <tr>
          <th style={{width: '60px'}}>Id</th>
          <th style={{width: '250px'}}>Наименование</th>
          <th>Предмет</th>
          <th>Язык</th>
          <th>Преподаватель</th>
          <th>Управление</th>
        </tr>
        {allCourses.map((course) => (
          <tr key={course.id}>
            <td style={{width: '60px'}}>
            {course.id}
            </td>
            <td style={{width: '250px'}}>
              {course.name}
            </td>
            <td>
              {course.subject.title}
            </td>
            <td>
              {course.lang == "ru" ? "Русский" : "Казахский"}
            </td>
            <td>
              {course.teacher ? course.teacher.name : ""}
            </td>
            <td>
              <div className="item-control">
                <Link to={`course/${course.id}`} className="btn btn-primary btn-flex"> 
                  <i className="fa-solid fa-pen"></i>
                </Link>
              </div>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
      {/* {findExistCourse.map((course) => (
        <MyCourseItem course={course} showLessons={false} key={course.id} />
      ))} */}
    </div>
  );
};
