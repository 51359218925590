import axios from "axios";
import React, { useEffect, useState } from "react";
// import { useAppSelector } from "src/hooks/redux";
import { authHeader } from "src/utils/authHeader";
import "./Friend.scss";
interface Referral {
  referral_code: string;
}

interface ReferralListItem {
  referral_id: string;
  order_id: string;
  created_at: string;
  discount: {
    course_count: number;
    amount: number;
  };
}

export const Friend = () => {
  document.title = "Мои оплаты | EasyENT";
  // const user = useAppSelector((store) => store.user.data);
  const [referal, setReferal] = useState<Referral | null>(null);
  const [referalList, setReferalList] = useState<ReferralListItem[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const referralResponse = await axios.get("/api/v1/referral/my", { headers: authHeader() });
        setReferal(referralResponse.data[0]);

        const referralListResponse = await axios.get("/api/v1/referral_usage/referred_with_my", { headers: authHeader() });
        setReferalList(referralListResponse.data);
      } catch (error) {
        console.error("Error fetching referral data", error);
      }
    };

    fetchData();
  }, []);
  const handleCopy = (referal_code:string) => {
    const url = "https://app.easyent.kz/buy/?refferal=$" + referal_code;
    navigator.clipboard.writeText(url)
        .then(() => {
            alert('ccылка скопирована');
        })
        .catch(err => {
            console.error('Failed to copy text: ', err);
        });
};
  return (
    <div className="box friend">
      <div className="friend-part">
        <div className="friend-part__left">
          <h1>Приводи друзей и зарабатывай деньги</h1>
          <p>У вас наверняка есть друзья, которые хотят подготовиться к ЕНТ на высокие баллы и поступить в Вуз мечты.
          </p>
          <p>В ваших силах помочь им это сделать. Пригласите их пройти обучение в EasyENT, где ваш друг сэкономит деньги, а вы заработаете.</p>
        </div>
        <div className="friend-part__right">
          <img src="/assets/gift.png"></img>
        </div>
      </div>
      <div className="friend-how">
        <span className="friend-how__heading">Как это работает</span>
        <ul className="how-ul">
          <li>Делитесь персональной ссылкой</li>
          <li>Друзья переходят по ссылке и покупают курс с дополнительной скидкой <b>1500</b> тг за каждый предмет</li>
          <li>Вы получаете денежный бонус <b>1500</b> тенге за каждый предмет, который купит друг</li>
        </ul>
        <p className="note-friend">
          <span>*Важное уточнение:*</span>
          Другу необходимо отучиться 1 месяц, чтобы начислились бонусы.<br></br>
          По реферальной ссылке могут покупать только новые ученики
        </p>
        <div className="friend-link">
            <span className="friend-link__heading">Реферальная ссылка: Делитесь ссылкой</span>
              {referal && referal.referral_code ? 
                (
                  <div className="referal">   
                    <p>https://app.easyent.kz/buy/?refferal={referal.referral_code}</p>
                    <button onClick={() => handleCopy(referal.referral_code)} className="btn btn btn-primary btn-flex">Копировать ссылку</button>
                  </div>
                ) : (
                  <div className="referal">   
                    Реферальной ссылки нету, попросите админа сгенерить для вас рефералку.<br></br>
                    Администратор: @sainsemey (ник в телеге)
                  </div>
                )
               }
        </div>
        <span className="friend-how__heading">Статистика</span>
        <table className="table table-friend">
          <tbody>
          <tr>
            <th>№ заказа</th>
            <th>Дата оплаты</th>
            <th>Бонусы</th>
          </tr>
          {referalList ? referalList.map((item:any) => (
            <tr key={item.referral_id}>
              <td>{item.order_id}</td>
              <td>{item.order.created_at}</td>
              <td>{item.discount_amount}</td>
            </tr>)) :
           ""
          }   
          </tbody>
        </table>
        <p>Всего Получено Бонусов: 0 тг</p>
      </div>
      <div className="friend-how friend-how--last">
        <span className="friend-how__heading">Два ученика, которые приведут больше всех друзей получат</span>
        <ul className="friendpr-ul">
          <li>
            <div className="friend-prize">
              <span className="friend-prize__heading">1 место</span>
              <span className="friend-prize__name">Iphone 16</span>
              <img className="friend-prize__img" src="/assets/iphone16.png"></img>
              
            </div>
          </li>
          <li>
            <div className="friend-prize">
              <span className="friend-prize__heading">2 место</span>
              <span className="friend-prize__name">Ipad 10</span>
              <img className="friend-prize__img" src="/assets/ipad10.png"></img>
              
            </div>
          </li>
        </ul>
        <div className="friend-result">Результаты проведем 17 октября. Зовите своих друзей в лучшую онлайн школу!</div>
      </div>  
    </div>
  );
};
