import axios from "axios";
import {useEffect, useState, FC } from "react";
import { authHeader } from "src/utils/authHeader";
import "./ModalUserHomework.scss";

export const ModalUserHomework: FC<{active:boolean; setActive:(value:boolean) => void; lessonNumber:number; studentId:number;  }> = 
  ({active, setActive, lessonNumber,studentId }) => {
    const [homework, sethomework] = useState<any>([]);

    useEffect(() => {
      axios
        .get(`/api/v1/homework/by_lesson_and_user/${lessonNumber}-${studentId}`, { headers: authHeader() })
        .then((response) => sethomework(response.data));
    }, []);
    // const cancelPenalty = () => {
    //   if(cancelReason){
    //     axios
    //     .post(`/api/v1/penalty/${penaltyid}/cancel`, cancelReason, {
    //       headers: authHeader(),
    //     })
    //     .then((response) => {
    //       if (response.status == 200) {
    //         alert('Пенальи отменен');
    //         setActive(false);
    //       }
    //     })
    //   }
    // }
    // console.log(cancelReason);
  return (
    <div className={active ? "modal active" : "modal"} onClick={()=> setActive(false)}>
      <div className="modal__content" onClick={e => e.stopPropagation()}>
        <div className="modal-info">
          
          {homework.length ? 
            (
              <span className="modal-content__heading">Домашка есть</span>
            ) :
            (
              <span className="modal-content__heading">Дз нету</span>
            )
          }
          {/* <input onChange={(e)=> setCancelReason({cancel_reason:e.target.value})} placeholder="Напишите причину" type="text"/>
          <button className="my-btn" onClick={()=> cancelPenalty()}>Отменить</button> */}
        </div>
        
        {/* <button onClick={()=> setActive(false)} className="my-btn">
          Закрыть окно
          <i className="fa-solid fa-xmark"></i>
        </button>  */}
      </div>
    </div>
  )
}
