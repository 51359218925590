import React, { useEffect, useState } from "react";
import axios from "axios";
import { JoinedUser } from "src/interface";
import { authHeader } from "src/utils/authHeader";
import "./AdminUsersJoined.scss";
import { ModalUserShow } from "src/components";

export const AdminUsersJoined = () => {
  document.title = "Пользователи Admin | EasyENT";
  const [users, setAllUsers] = useState<JoinedUser[]>([]);
  const [courses, setCourses] = useState<any>([]);
  const [selectedCourse, setSelectedCourse] = useState<string | null>(null);
  const [modalUserShowActive, setModalUserShowActive] = useState(false);
  const [userId, setUserId] = useState<number>(0);  
  useEffect(() => {
    if(selectedCourse !== null){
      axios
      .get(`/api/v1/courses/${selectedCourse}/tg_joined_users/?skip=0&limit=500`, { headers: authHeader() })
      .then((response:any) => setAllUsers(response.data));
    }
  }, [selectedCourse]);

  useEffect(() => {
    axios
      .get("/api/v1/courses?skip=0&limit=500&lang=ru&active=true", { headers: authHeader() })
      .then((response: any) => {
        setCourses(response.data); 
      })
      .catch((error) => console.error("Error fetching courses:", error));
  }, []);
  const openUserModal = (userid: number) => {
    setUserId(userid);
    setModalUserShowActive(true);           
  };
  const handleCourseChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCourse(event.target.value);
  };
  return (
    <div>
      <h1 className="title title--2">Подписка на курсы</h1> 
      <div className="user-form box">
        <div className="flex">   
          <div className="white-div">
            <select
              className="white-div__select white-div__select--big"
              onChange={handleCourseChange}
              value={selectedCourse || ""}
            >
              <option value="" disabled>
                Выберите курс
              </option>
              {courses.map((course:any) => (
                <option key={course.id} value={course.id}>
                  {course.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div>
        Количество учеников: {users.length}
      </div>
      <table className="table">
        <tbody>
          <tr>
            <th style={{ width: '40px' }}>Id</th>
            <th style={{ width: '210px' }}>Фио</th>
            <th style={{ width: '180px' }}>Курс</th>
            <th style={{ width: '140px' }}>Подписка</th>
            <th style={{ width: '80px' }}></th>
          </tr>
          {users.map((user) => (
            <tr key={user.user_id}>
              <td style={{ width: '40px' }}>{user.user_id}</td>
              <td style={{ width: '220px' }}>{user.full_name}</td>
              <td style={{ width: '140px' }}>{user.course_name}</td>
              <td style={{ width: '90px' }}>{user.last_join_date ? user.last_join_date : "X"}</td>
              <td style={{ width: '130px' }}>
                <div className="item-control">
                  <div onClick={() => openUserModal(user.user_id)} className="item-control__item">
                    <i className="fa-solid fa-pen"></i>
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {modalUserShowActive && userId !== null && (
        <ModalUserShow
          active={modalUserShowActive}
          user_id={userId}
          setActive={setModalUserShowActive}
        />
      )}
    </div>
  );
};
