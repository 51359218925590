import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import {
  AdminAddQuestion,
  ILesson,
  IQuestion,
  QuestionType,
} from "src/interface";
import { authHeader } from "src/utils/authHeader";
import axios from "src/utils/axios";

export const TeacherHomeworkView = () => {
  document.title = "Дз правка | EasyENT";
  const params = useParams();
  const location: any = useLocation();
  const [questions, setQuestions] = useState<IQuestion[] | any>([]);
  const [prevQuestions, setPrevQuestions] = useState<IQuestion[] | any>([]);
  const [, setlesson] = useState<ILesson>({});
  const [uploadPhoto, setUploadPhoto] = useState({
    photofile: "string",
  });
  useEffect(() => {
    axios
      .get("/api/v1/lessons/" + lesson_id, { headers: authHeader() })
      .then((response) => setlesson(response.data));
  }, []);
  let lesson_id = 0;
  if (params.lesson_id) {
    lesson_id = parseInt(params.lesson_id);
  }
  const [question, setQuestion] = useState<AdminAddQuestion>({
    lesson_id: lesson_id,
    sequence: 0,
    display_name: "string",
    answer: "",
    question_type: QuestionType.choice,
    solution: "",
  });

  useEffect(() => {
    getLessonQuestions();
  }, [uploadPhoto]);

  const getLessonQuestions = () => {
    axios
      .get("/api/v1/question/?lesson_id=" + params.lesson_id, {
        headers: authHeader(),
      })
      .then((response) => {
        // Sort the questions by sequence
        const sortedQuestions = response.data.sort(
          (a: IQuestion, b: IQuestion) => (a.sequence || 0) - (b.sequence || 0)
        );
  
        setQuestions(sortedQuestions);
        setPrevQuestions(JSON.parse(JSON.stringify(sortedQuestions)));
  
        // Update the question's sequence for the next new question
        setQuestion({ ...question, sequence: sortedQuestions.length + 1 });
      });
  };

  const UploadSolutionPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formdata = new FormData();
    const question_id = parseInt(event.target.id);
    if (event.target.files?.length) {
      formdata.append("file", event.target.files[0]);
      axios
        .post(
          "/api/v1/question/" + question_id + "/solution_image",
          formdata,
          { headers: authHeader() }
        )
        .then(() => {
          setUploadPhoto({ ["photofile"]: event.target.value });
        });
    }
  };
  
  const onUpdateQuestion = (question: AdminAddQuestion) => {
    axios
      .put("/api/v1/question/" + question.id, question, {
        headers: authHeader(),
      })
      .then(() => {
        console.log('12')
      })
      .catch(() => {
        // alert(error.response.data);
      });
  };

  return (
    <div className="my-course">
      <ul className="breadcrumbs">
        <li>
          <Link to="/admin/courses">Курсы</Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <Link to={`/admin/courses/course/${location?.state?.courseId}`}>
            {location?.state?.courseName}
          </Link>
        </li>
        <li>
          <span>
            {location?.state?.courseId && (
              <i className="fa-solid fa-arrow-right"></i>
            )}
          </span>
        </li>
        <li>
          <span>Домашка куратора</span>
        </li>
      </ul>
      <h1 className="admin-title">Просмотр домашки</h1>
      <div className="box homework">
        <ul className="homework-ul">
          {questions?.map((question: any, index: any) => (
            <li key={question.id}>
              <div className="question-row">
                <div className="question-admin__heading">
                  <span className="question-row__heading">
                    <span>{index + 1 + ")"}</span>
                    {question.display_name}
                    {question.image !== null ? (
                        <div className="question__img">
                          <img src={question.image}></img>
                        </div>
                      ) : (
                        ""
                    )}
                  </span>
                </div>
                {question["question_type"] &&
                question["question_type"] == QuestionType.choice ? (
                  <div className="question-row__area">
                    {question["question_choise"] !== undefined &&
                    question["question_choise"]["values"] !== undefined &&
                    question["question_choise"]["values"].length > 0 ? (
                      question["question_choise"]["values"]
                        .sort((a: any, b: any) => a?.seq_idx - b?.seq_idx)
                        .map((choice: any) => (
                          <div key={choice.id} className="input-row input-row--second">
                            <div className="admin-choice">
                              {question.answer == choice.value ? (
                                <div className="tipo-input">
                                  <i className="fa-solid fa-check"></i>
                                </div>
                              ) : (
                                <div
                                  data-sequence={index + 1}
                                  data-value={choice.value}
                                  data-quesiton-id={"" + question.id}
                                  className="tipo-input"
                                ></div>
                              )}
                              <div className="admin-choise__right">
                                {choice.image !== null ? (
                                  <div className="admin-choice-row__img">
                                    <img src={choice.image}></img>
                                  </div>
                                ) : 
                                  (
                                  <label htmlFor={"" + choice.id}>
                                    {choice.value}
                                  </label>
                                  )
                                }  
                              </div>
                            </div>
                            {choice.image !== null ? (
                              <div className="adminreal-choice-row__img">
                                <img
                                  src={"https://app.easyent.kz/" + choice.image}
                                ></img>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ))
                    ) : (
                      <div>пусто</div>
                    )}
                  </div>
                ) : (
                  <div className="task-area">
                    {/* <div className="task-area__input">
                      <input type="file" name={"file"+question.id} id={"file"+question.id} onChange={UploadPhoto} accept="image/png, image/jpeg"></input>
                    </div>
                    <textarea placeholder="Написать ответ"  className="task-area__textarea"></textarea> */}
                  </div>
                )}
                <div className="admin-choice-row">
                  <div className="admin-choice-row__solution">
                    <div className="solution-row">
                      <label>Текст решения:</label>
                      <input
                        className="solution-row__input"
                        onChange={(e) => {
                          const newQuestions = [...questions];
                          newQuestions[index].solution = e.target.value;
                          setQuestions(newQuestions);
                        }}
                        onBlur={() => {
                          if (
                            prevQuestions?.[index]?.solution !==
                            questions?.[index]?.solution
                          ) {
                            onUpdateQuestion(question);
                          }
                        }} 
                        value={question.solution ? question.solution : ""}
                        id={"" + question.id}
                        type="text"
                      >
                      </input>
                    </div>
                    <div>
                      <label>Фото решения:</label>
                      <input
                        id={"" + question.id}
                        onChange={UploadSolutionPhoto}
                        accept="image/png, image/jpeg"
                        type="file"
                      >
                      </input> 
                    </div>
                  </div>
                  {question.solution_image ? 
                    (
                      <div className="realadmin-choice-row__img">
                        <img src={"https://app.easyent.kz/" + question.solution_image}></img>
                      </div>
                    ) : ""
                  }
                  
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>  
    </div>
  );
};
