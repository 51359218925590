import React, { useState } from "react";
import axios from "axios";
import { UserSlice } from "src/service/reducers/UserSlice";
import { login, getUser } from "../../service/asyncThunk";
import "./Register.scss";
import { useAppDispatch } from "src/hooks/redux";
import { useNavigate } from "react-router-dom";

export const Register = () => {
  const { updateUser, setToken } = UserSlice.actions;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
  });

  const [users, setUsers] = useState({
    full_name: "",
    phone: "",
    email: "",
    lang: "ru",
  });

  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (password.password === password.confirmPassword) {
      const newUser = {
        ...users,
        password: password.password,
      };
      axios
        .post("/api/v1/users/open", newUser)
        .then((response) => {
          if (response.status == 200) {
            // dispatch(registerUser(newUser));
            // navigate("/notification");

            // Временно авторизовываем
            dispatch(
              login({
                email: users.email,
                password: password.password,
                client_id: null
              })
            ).then((res: any) => {
              if (!res?.error?.message) {
                dispatch(setToken(res));
                dispatch(getUser()).then((sres: any) => {
                  if (!res?.sres?.message) {
                    dispatch(updateUser(sres.payload));
                    navigate("/");
                  }
                });
              }
            });
            // Временно авторизовываем
          }
        })
        .catch((error) => {
          alert(error.response.data);
        });
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const value = element.value;
    const name = element.name;
    setUsers((s) => ({ ...s, [name]: value }));
  };

  // const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const value = event.target.value;
  //   setUsers((s) => ({ ...s, [event.target.name]: value }));
  // };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const value = element.value;
    const name = element.name;
    setPassword((s) => ({ ...s, [name]: value }));
  };

  return (
    <div className="register-container">
      <form className="register-form" onSubmit={onSubmit}>
        <h1>Регистрация</h1>
        <input
          type="text"
          name="full_name"
          value={users.full_name}
          onChange={onChange}
          placeholder="ФИО"
          required
          onInput={(e) => {
            const element = e.target as HTMLInputElement;
            if (/[a-zA-Z]/.test(element.value)) {
              element?.setCustomValidity("Введите символы на кириллице");
            } else {
              element?.setCustomValidity("")
            }
          }}
        />
        <input
          type="tel"
          onChange={onChange}
          name="phone"
          required
          placeholder="Номер телефона"
        />
        <input
          type="text"
          name="email"
          onChange={onChange}
          required
          placeholder="Почта"
        />
        {/* <input
          type="text"
          name="zoom_account"
          onChange={onChange}
          required
          placeholder="@ телеграм аккаунт"
        /> */}
        <input
          type="password"
          name="password"
          onChange={onChangePassword}
          placeholder="Придумайте пароль"
          minLength={6}
          required
        />
        <input
          type="password"
          name="confirmPassword"
          onChange={onChangePassword}
          minLength={6}
          required
          placeholder="Повторите пароль"
        />
        <label className="register-public">
          <p>Нажимая зарегистрироваться вы соглашаетесь с <a target="_blank" href="https://easyent.kz/app/webroot/pdf/oferta.pdf">публичной офертой</a></p>
        </label>
        <button className="btn btn-primary btn-flex">
          Зарегистрироваться <i className="fa-solid fa-user-plus"></i>
        </button>
      </form>
    </div>
  );
};
