import axios from "axios";
import React, { useEffect, useState} from "react";
import { useParams, Link } from "react-router-dom";
import { useForm} from "react-hook-form";
import { ILesson, QuestionType } from "src/interface";
import { authHeader } from "src/utils/authHeader";
import { ModalAttachment } from "src/components";
import moment from "moment";
import "./AnalitycsPageView.scss";

export const AnalitycsPageView = () => {
  document.title = "Домашнее задание | EasyENT";
  const params = useParams();
  const [lesson, setlesson] = useState<ILesson>({});
  const [corrcetCount, setCorrcetCount] = useState<number>(0);
  const [homeworkPercent, setHomeworkPercent] = useState<number | undefined>(0);
  const [homeworkDate, setHomeworkDate] = useState<Date | undefined>();
  const [attachamentModalActive, setAttachamentModalActive] = useState(false);
  const [attachmentPath, setAttachmentPath] = useState<string | null>(null); 
  const {
    register,
  } = useForm();
  
  useEffect(() => {
    let correctNumber = 0;
    axios
      .get("/api/v1/lessons/" + params.id, { headers: authHeader() })
      .then((response) => {
        setlesson(response.data);
        if(response.data.questions[0]["homeworks"].length){
          setHomeworkDate(response.data.questions[0]["homeworks"][0]["write_date"]);
        }
        setHomeworkPercent(response.data.correct_homework_percent);
        for (const q in response.data.questions) {
          for (const h in response.data.questions[q]["homeworks"]) {
            register("value" + response.data.questions[q]["id"], {
              value: response.data.questions[q]["homeworks"][h]["value"],
              disabled: true,
            });
            if(response.data.questions[q]["homeworks"][h]["is_correct"] == true){
              correctNumber += 1;
            }
          }
        }
        setCorrcetCount(correctNumber);
      });
  }, []);

  const answered = function (id: number) {
    if (lesson && lesson.questions) {
      const question = lesson.questions.filter(
        (q) =>
          q.id === id &&
          q.homeworks &&
          typeof (q.homeworks, "array") &&
          q.homeworks.length > 0
      );
      if (question.length > 0) {
        return true;
      }
    }
    return false;
  };

  const correctAnswer = function (
    id: number,
    value: string | undefined
  ): string {
    if (lesson && lesson.questions && value) {
      const question = lesson.questions.filter(
        (q) =>
          q.id === id &&
          q.homeworks &&
          typeof (q.homeworks, "array") &&
          q.homeworks.length > 0 &&
          q.homeworks[0].value === value
      );

      if (question.length > 0) {
        if (question[0]?.homeworks?.[0]?.is_correct == false) {
          return "input-radio__incorrect";
        } else if (question[0]?.homeworks?.[0]?.is_correct == true) {
          return "input-radio__correct";
        }
      }

      const questionChoice = lesson.questions
        .find(
          (q) =>
            q.id === id &&
            q.homeworks &&
            typeof (q.homeworks, "array") &&
            q.homeworks.length > 0
        )
        ?.question_choise?.values?.find(
          (c: any) => c.value === value && c.is_correct
        );

      if (questionChoice) {
        return "input-radio__correct";
      }
    }
    return "";
  };

  const getAttachment = (id: number) => {
    const question = lesson?.questions?.find((question) => question.id == id);
    if (question?.homeworks?.[0]) {
      const obj = question?.homeworks?.[0];
      if (Object.prototype.hasOwnProperty.call(obj, "attachment")) {
        const attachment = question?.homeworks?.[0].attachment;
        return attachment;
      }
      return;
    } else {
      return;
    }
  };
  function getDeadLineText(lesson: ILesson) {
    if (lesson?.end_date) {
      const endDate = moment(lesson.end_date);
      const deadLine = moment(endDate).add(3, "days");
      const todaysDate = moment();
      const daysDifference = deadLine.diff(todaysDate, "days");
      if (daysDifference < 0) {
        return ``;
      } else if (daysDifference === 0) {
        return "Сегодня последний день";
      } else if (daysDifference > 0 && daysDifference < 4) {
        return `Осталось ${Math.abs(daysDifference)} дн.`;
      } else {
        return ``;
      }
    }
  }
  const openAttachmentModal = (attachmentPath: string) => {
    setAttachamentModalActive(true);  
    setAttachmentPath(attachmentPath); 
  };
  return (
    <>
      <ul className="breadcrumbs">
        <li>
            <Link to={`/course_${lesson?.course_id}`}>
              Аналитика ошибок
            </Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
            <Link to={`/course_${lesson?.course_id}`}>
              {lesson?.course?.name}
            </Link>
        </li>
        <li>
          <span>
            {lesson?.course_id && <i className="fa-solid fa-arrow-right"></i>}
          </span>
        </li>
        <li>
          <span>
            {lesson?.course_id && `Домашнее задание - ${lesson?.name}`}
          </span>
          {getDeadLineText(lesson) && (
            <span className="homework-lesson__deadline">
              {getDeadLineText(lesson)}
            </span>
          )}
        </li>
      </ul>
             
      <div className="homework box">
        <div className="analitycs-top">
            <div className="analitycs-top__left">
              <span className="analitycs-top__heading">{lesson.name}</span>
              {homeworkPercent ? 
                (
                  <div className="analitycs-top__row">
                    <p>
                      <i className="fa-solid fa-square-check"></i>
                      {corrcetCount} из {lesson.questions ? lesson.questions.length : 0} - 
                      <span> {homeworkPercent.toString().split(',')[0]}%</span> прав.ответов</p>
                  </div>
                )
              : ""}
            </div>
            <div className="analitycs-top__right">
              <div className="homework-date">
                <p><span>Дата сдачи дз:</span></p> 
                <p>{homeworkDate ? homeworkDate.toString().substring(0, 10) : ""}</p>
              </div>
            </div>
        </div>
          <ul className="homework-ul">
            {lesson["questions"] !== undefined ? (
              lesson["questions"]
              .sort((a: any, b: any) => a?.sequence - b?.sequence)
              .map((question, number) => (
                <li key={question.id}>
                  <div className="question-row">
                    <span className="question-row__heading">
                      <span>{number + 1}) </span> {question.display_name}
                      {question.image !== null ? (
                        <div className="question__img">
                          <img src={question.image}></img>
                        </div>
                      ) : (
                        ""
                      )}
                    </span>
                    {question["question_type"] &&
                    question["question_type"] == QuestionType.choice ? (
                      <div className="question-row__area">
                        {question["question_choise"] !== undefined &&
                        question["question_choise"]["values"] !== undefined &&
                        question["question_choise"]["values"].length > 0 ? (
                          question["question_choise"]["values"].map(
                            (choice) => (
                              <div key={choice.id} className="input-row">
                                <input
                                  {...register("value" + question.id)}
                                  value={choice.value}
                                  id={"" + choice.id}
                                  type="radio"
                                  disabled={answered(question.id)}
                                />
                                {choice.image !== null ? (
                                  <label
                                    htmlFor={"" + choice.id}
                                    className={
                                      lesson.homework_visible == true
                                        ? correctAnswer(
                                            question.id,
                                            choice.value
                                          )
                                        : ""
                                    }
                                  >
                                    <div className="admin-choice-row__img">
                                      <img src={choice.image}></img>
                                    </div>
                                  </label>
                                ) : (
                                  <label
                                    htmlFor={"" + choice.id}
                                    className={
                                      lesson.homework_visible == true
                                        ? correctAnswer(
                                            question.id,
                                            choice.value
                                          )
                                        : ""
                                    }
                                  >
                                    {choice.value}
                                  </label>
                                )}
                              </div>
                            )
                          )
                        ) : (
                          <div>пусто</div>
                        )}
                      </div>
                    ) : (
                      <div className="task-area">
                        <div className="task-area__input">
                          <label>Прикрепите решение: </label>
                          <input
                            type="file"
                            name={"file" + question.id}
                            id={"file" + question.id}
                            accept="image/png, image/jpeg"
                          ></input>
                        </div>
                        {question.homeworks && question.homeworks.length > 0  ? (
                            <img
                              className="task_attachment"
                              src={"https://app.easyent.kz" + getAttachment(question.id)}
                            />

                          ) : ""
                        }   
                      </div>
                    )}
                  </div>
                  <div className="student-solution">
                      <label>Решение:</label>
                      {question.solution ? (
                        <p>{question.solution}</p>
                      ) : ""}
                      {question.solution_image ? 
                          (
                            <div className="realadmin-choice-row__img">
                              <img  onClick={() => openAttachmentModal(question.solution_image ?  question.solution_image : "")} src={"https://app.easyent.kz" + question.solution_image}></img>
                            </div>
                          ) : ""
                      }
                  </div>
                </li>
              ))
            ) : (
              <div>Загрузка</div>
            )}
          </ul>
          {attachamentModalActive && attachmentPath !== null && (
            <ModalAttachment
              active={attachamentModalActive}
              attachment={attachmentPath} 
              setActive={setAttachamentModalActive}
            />
          )}
      </div>
    </>
  );
};
