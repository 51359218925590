import React, { FC, useState } from "react";
import { Link } from "react-router-dom";
import { useAppSelector } from "src/hooks/redux";
import "./Header.scss";

export const Header: FC<{ stateNavShow?:(value: boolean) => void, isEmpty?: boolean }> = ({ stateNavShow, isEmpty }) => {  
  if (isEmpty) {
    return (
      <div className="empty-header">
        <div className="empty-header__container">
          <Link to="/Login"><img className="logo" src={"/assets/svg/EasyEnt.svg"} /></Link>
        </div>  
      </div>
    );
  }
  const user = useAppSelector((store) => store.user.data);
  

  const isUserHaveImage = (
    <div className="img">
      <img
        src={
          user.image === null
        
            ? "/assets/svg/ava.svg"
            : user.image
        }
      />
    </div>
  );
  const [showMenu, setShowMenu] = useState(false);
  const handleShowMenu = () => {
    setShowMenu((s) => !s);
  };

 
  const showUserName = `${user.full_name}`;
  const [showNav, setShowNav] = useState(false);    
  const handleShowNav = () => {
    if(stateNavShow !== undefined){
      stateNavShow(!showNav);
      setShowNav(!showNav);
    }
  }
  return (
    <header className="header">
      <div className="container">  
        <div onClick={handleShowNav} className="mob-start">
          <span className="menu1"></span>
          <span className="menu2"></span>
          <span className="menu3"></span>
        </div>
        <Link to="/my-courses" className="header-logo">
          <img src="/assets/svg/EasyEnt.svg"/>
        </Link>
        <div className="header__right">
          <div className="user-info">
            {isUserHaveImage}
            <div className="person-name">
              <p onClick={handleShowMenu}>
                {showUserName} <i className="fa-solid fa-angle-down"></i>
              </p>
              <div className="advancedMenu" style={{ display: showMenu ? "block" : "none" }}>
                <Link onClick={handleShowMenu} to="/my-profile">
                  Мой профиль
                </Link>
                <Link onClick={handleShowMenu} to="/subscriptions">
                  Мои оплаты
                </Link>
              </div>
            </div>
          </div>
          <div className="header__dekright">
          </div>
        </div>
      </div>
    </header>
  );
};
