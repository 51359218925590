import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  ICourse,
  ILesson,
  // AddLesson,
  // lessonTypeOption,
} from "src/interface";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import "moment/locale/ru";
moment.locale("ru");
import "./TeacherСourseView.scss";
import { authHeader } from "src/utils/authHeader";
// import { useTranslation } from "react-i18next";
// import { namespaces } from "src/i18n/i18n.constants";
import { useAppSelector } from "src/hooks/redux";

export const TeacherСourseView = () => {
  document.title = "Курсы | EasyENT";
  const params = useParams();
  const [course, setCourse] = useState<ICourse>();
  const [lessons, setLessons] = useState<ILesson[]>([]);
  const [curator, setCurator] = useState<boolean>();
  // const [time, setTime] = useState<string>("");
  // const { t } = useTranslation(namespaces.pages.hello);
  const getLessonDay = (day: Date) => {
    const lessonDay = moment(day).format("ll");
    return lessonDay;
  };
  // const [lessonType, setLessonType] = useState({
  //   value: "lesson",
  //   label: "Урок",
  // });
  // const today = moment();
  const user = useAppSelector((store) => store.user.data);
  // let course_id = 0;
  let lessoncounter = 0;
  // if (params.id) {
  //   course_id = parseInt(params.id);
  // }

  // const lessontypes: lessonTypeOption[] = [
  //   { value: "lesson", homework_visible: false, label: "Урок" },
  //   { value: "control", homework_visible: true, label: "Контрольная работы" },
  // ];

  // const [lesson, setLesson] = useState<AddLesson>({
  //   course_id: course_id,
  //   name: "",
  //   start_date: "",
  //   end_date: "",
  //   lesson_type: "lesson",
  //   time_code: [],
  //   homework_visible: true,
  //   questions: [],
  // });

  function getDeadLineText(lesson: ILesson) {
    if (lesson?.end_date) {
      const endDate = moment(lesson.end_date);
      const deadLine = moment(endDate).add(3, "days");
      const todaysDate = moment();
      const daysDifference = deadLine.diff(todaysDate, "days");
      if (daysDifference < 0) {
        return ``;
      } else if (daysDifference === 0) {
        return "Сегодня последний день";
      } else if (daysDifference > 0 && daysDifference < 4) {
        return `Осталось ${Math.abs(daysDifference)} дн.`;
      } else {
        return ``;
      }
    }
  }

  useEffect(() => {
    axios
      .get("/api/v1/courses/" + params.id, { headers: authHeader() })
      .then((response) => {
        setCourse(response.data);
      });
    axios
      .get("/api/v1/courses/" + params.id + "/lessons/", {
        headers: authHeader(),
      })
      .then((response) => {
        setLessons(response.data);
      });
    if (!user.id) {
      console.log("пустo");
    } else {
      if (user.is_manager === true) {
        setCurator(true);
      } else {
        setCurator(false);
      }
    }
  }, []);

  const CopyUrl = (e: React.MouseEvent<HTMLInputElement>) => {
    const element = e.currentTarget;
    const url = element.getAttribute("data-link") + "";
    navigator.clipboard.writeText(url) // Copy the URL to clipboard
    .then(() => {
      alert('Ссылку скопирована');
    })
    .catch(err => {
      console.error('Failed to copy: ', err);
    });
  }  


  return (
    <div className="my-course">
      <ul className="breadcrumbs">
        <li>
          <a href="/teacher">Мои курсы</a>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <span>{course ? course.name : "загрузка"}</span>
        </li>
      </ul>
      <h1 className="title">{course ? course.name : "загрузка"}</h1>
      <div className="my-course__info">
        {lessons.length !== 0 && curator !== null ? (
          <div>
            {lessons.map((lesson) => {
              if (lesson.lesson_type != "control") lessoncounter += 1
              return (
                <div className="lesson-сard lesson-сard--admin" key={lesson.id}>
                  <div className="lesson-card__content">
                    <span className="lesson-card__date">
                      {lesson.start_date
                        ? getLessonDay(lesson.start_date)
                        : "Нету даты"}
                      {getDeadLineText(lesson) != "" ? (
                        <span className="lesson-card__deadline">
                          {getDeadLineText(lesson)}
                        </span>
                      ) : (
                        <></>
                      )}
                    </span>
                    <div className="lesson-card__heading">
                      {lesson.lesson_type == "control" ? (
                        ""
                      ) : (
                        <span>{lessoncounter} Урок:</span>
                      )}{" "}
                      {lesson.name}
                    </div>
                    <div className="lesson-card__buttons">
                      {curator !== true ? (
                        <>
                          <Link
                            className="btn-work "
                            to={course ? `/teacher/homework/${lesson.id}` : ""}
                            state={{
                              courseId: course?.id,
                              courseName: course?.name,
                            }}
                          >
                            Домашнее задание
                          </Link>
                          <Link
                            to={`/teacher/update/${lesson.id}`}
                            className="btn-work"
                          >
                            Обновить урок
                            <i className="fa-solid fa-angle-right"></i>
                          </Link>
                          <Link
                          className="btn-work btn-empty"
                          to={
                            course
                              ? `/teacher/course_${course.id}/homeworks_by_lesson/${lesson.id}`
                              : ""
                          }
                          state={{
                            courseId: course?.id,
                            courseName: course?.name,
                          }}
                        >
                          Дз от учеников {" "}
                        </Link>
                        </>
                      ) : (
                        <>
                          {
                            lesson.lesson_type == "control" ?
                              (
                              <Link
                              className="btn-work "
                              to={course ? `/teacher/homework/${lesson.id}` : ""}
                              state={{
                                courseId: course?.id,
                                courseName: course?.name,
                              }}
                            >
                              Контрольная работа 
                            </Link>
                            ) : ""
                          }
                          <Link
                            className="btn-work"
                            to={
                              course
                                ? `/teacher/course_${course.id}/homeworks_by_lesson/${lesson.id}`
                                : ""
                            }
                            state={{
                              courseId: course?.id,
                              courseName: course?.name,
                            }}
                          >
                            Дз от учеников {" "}
                          </Link>
                          <Link
                            className="btn-work"
                            to={
                              course
                                ? `/teacher/course_${course.id}/material/${lesson.id}`
                                : "" 
                            }
                            state={{
                              courseId: course?.id,
                              courseName: course?.name,
                            }}
                          >
                            Домашка {" "}
                          </Link>
                          {
                            lesson.zoom_link ? 
                            <div  onClick={CopyUrl} data-link={lesson.zoom_link} className="btn-work">Копировать ссылку</div>
                            : ""
                          }
                          
                        </>
                      )}

                      
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="box">Уроков нету</div>
        )}
      </div>
    </div>
  );
};
