import React, { FC } from "react";
import "./ModalAttachment.scss";

export const ModalAttachment: FC<{active:boolean,setActive:(value:boolean) => void, attachment:string}> = 
  ({active, setActive, attachment}) => {
  return (
    <div className={active ? "modal-photo active" : "modal-photo"} onClick={()=> setActive(false)}>
      <div className="modal-photo__content" onClick={e => e.stopPropagation()}>
        <img src={"https://app.easyent.kz" + attachment}></img>
      </div>
    </div>
  )
}
