import React, { useEffect, useState } from "react";
import axios from "src/utils/axios";
import {
  IQuestion,
  AdminAddQuestion,
  IChoice,
  AdminQuestion_choice,
  ILesson,
  QuestionType,
} from "src/interface";
import { useParams, Link, useLocation } from "react-router-dom";
import { ModalHomework } from "src/components";
import { authHeader } from "src/utils/authHeader";

export const AddHomework= () => {
  document.title = "Дз правка | EasyENT";
  const params = useParams();
  const location: any = useLocation();
  const [questions, setQuestions] = useState<IQuestion[] | any>([]);
  const [modalActive, setModalActive] = useState(false);
  const [lesson, setlesson] = useState<ILesson>({});
  const [prevQuestions, setPrevQuestions] = useState<IQuestion[] | any>([]);
  const [uploadPhoto, setUploadPhoto] = useState({
    photofile: "string",
  });
  const [question_choise, setQuestion_choise] = useState<AdminQuestion_choice[]>([]);
  let lesson_id = 0;
  if (params.lesson_id) {
    lesson_id = parseInt(params.lesson_id);
  }
  useEffect(() => {
    axios
      .get("/api/v1/lessons/" + lesson_id, { headers: authHeader() })
      .then((response) => setlesson(response.data));
  }, []);
  const [question, setQuestion] = useState<AdminAddQuestion>({
    lesson_id: lesson_id,
    sequence: 0,
    display_name: "string",
    answer: "",
    solution: "",
    question_type: QuestionType.choice,
  });

  useEffect(() => {
    getLessonQuestions();
  }, [uploadPhoto]);

  const getLessonQuestions = () => {
    axios
      .get("/api/v1/question/?lesson_id=" + params.lesson_id, {
        headers: authHeader(),
      })
      .then((response) => {
        setQuestions(response.data);
        setPrevQuestions(JSON.parse(JSON.stringify(response.data)));
        setQuestion({ ...question, ["sequence"]: questions.length + 1 });
      });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const value = element.value;
    const name = element.name;
    setQuestion((s) => ({ ...s, [name]: value }));
  };
  const selectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setQuestion((s) => ({ ...s, [event.target.name]: value }));
  };

  const onChangeChoice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const value = element.value;
    const name = element.name;
    const newQuestion = question;
    if (question_choise.filter((e) => e.name === name).length > 0) {
      const i = question_choise.findIndex((e) => e.name === name);
      question_choise[i]["value"] = value;
      if(question_choise[i]["is_correct"] == true){
        newQuestion.answer = value;
      }
    } else {
      const newChoice: AdminQuestion_choice = {
        name: name,
        value: value,
        is_correct: false,
      };
      question_choise.push(newChoice);
    }
    setQuestion_choise(question_choise);
    newQuestion.question_choise =  {
      name: question.display_name,
      values: question_choise,
    };
    setQuestion(() => (newQuestion));
  };

  const onChangeCorrect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const elementid = element.id;
    let myanswer = "";
    if (question_choise.filter((e) => e.name === elementid).length > 0) {
      const i = question_choise.findIndex((e) => e.name === elementid);
      question_choise[i]["is_correct"] = true;
      myanswer = question_choise[i]["value"];
    }
    setQuestion_choise(question_choise);
    const question_choice = {
      name: question.display_name,
      values: question_choise,
    };
    setQuestion((s) => ({
      ...s,
      ["answer"]: myanswer,
      ["question_choise"]: question_choice,
    }));
  };
  function isUnique(arr:any) {
    const tmpArr = [];
    for(const obj in arr) {
      if(tmpArr.indexOf(arr[obj].value) < 0){ 
        tmpArr.push(arr[obj].value);
      } else {
        return false; 
      }
    }
    return true; 
 }
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newObj = question;
    newObj["sequence"] = questions.length + 1;
    if (question.question_type == QuestionType.choice) {
      if (question.answer !== "") {
        console.log(newObj);
        const unique = isUnique(newObj['question_choise']['values']);
        if(unique){
            axios
            .post("/api/v1/question/bulk", newObj, { headers: authHeader() })
            .then((response) => {
              if (response.status == 200) {
                window.location.reload();
              }
            })
            .catch((error) => {
              alert(error.response.data);
            });
        }
        else{
          alert('Каждое значение должно быть уникальным');
        }
        
      } else {
        alert("Выберите правильный ответ");
      }
    } else { 
      axios
        .post("/api/v1/question/", newObj, { headers: authHeader() })
        .then((response) => {
          if (response.status == 200) {
            window.location.reload();
          }
        })
        .catch((error) => {
          alert(error.response.data);
        });
    }
  };

  const UploadPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formdata = new FormData();
    const question_choise_id = parseInt(event.target.id);
    if (event.target.files?.length) {
      formdata.append("file", event.target.files[0]);
      axios
        .post(
          "/api/v1/question_choise_value/" + question_choise_id + "/image",
          formdata,
          { headers: authHeader() }
        )
        .then(() => {
          setUploadPhoto({ ["photofile"]: event.target.value });
        });
    }
  };
  const UploadQuestionPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formdata = new FormData();
    const question_id = parseInt(event.target.id);
    if (event.target.files?.length) {
      formdata.append("file", event.target.files[0]);
      axios
        .post("/api/v1/question/" + question_id + "/image", formdata, {
          headers: authHeader(),
        })
        .then(() => {
          setUploadPhoto({ ["photofile"]: event.target.value });
        });
    }
  };
  const UploadSolutionPhoto = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formdata = new FormData();
    const question_id = parseInt(event.target.id);
    if (event.target.files?.length) {
      formdata.append("file", event.target.files[0]);
      axios
        .post(
          "/api/v1/question/" + question_id + "/solution_image",
          formdata,
          { headers: authHeader() }
        )
        .then(() => {
          setUploadPhoto({ ["photofile"]: event.target.value });
        });
    }
  };

  const onChangeChoiceValue = (e: React.MouseEvent<HTMLInputElement>) => {
    const element = e.currentTarget;

    const questionId = element.getAttribute("data-quesiton-id");
    const questionIdnumber = parseInt("" + questionId);
    const index: number = questions.findIndex(
      (questions: any) => questions.id === questionIdnumber
    );
    const name = questions[index]["display_name"];
    const myobj = {
      answer: element.getAttribute("data-value"),
      display_name: name,
      sequence: parseInt("" + element.getAttribute("data-sequence")),
      question_type: "choice",
    };
    axios
      .put("/api/v1/question/" + questionIdnumber, myobj, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          window.location.reload();
        }
      })
      .catch(() => {
        // alert(error.response.data);
      });
  };

  const onUpdateQuestion = (question: AdminAddQuestion) => {
    axios
      .put("/api/v1/question/" + question.id, question, {
        headers: authHeader(),
      })
      .then(() => {
        getLessonQuestions();
      })
      .catch(() => {
        // alert(error.response.data);
      });
  };

  const onUpdateChoice = (choice: IChoice) => {
    axios
      .put("/api/v1/question_choise_value/" + choice.id, choice, {
        headers: authHeader(),
      })
      .then(() => {
        getLessonQuestions();
      })
      .catch(() => {
        // alert(error.response.data);
      });
  };

  const DeleteQuestion = (e: React.MouseEvent<HTMLButtonElement>) => {
    const element = e.currentTarget;
    const questionId = element.getAttribute("data-quesiton-id");
    const questionIdnumber = parseInt("" + questionId);
    axios
      .delete("/api/v1/question/" + questionIdnumber, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 204 || response.status == 200) {
          alert('Вопрос успешно удален');
          window.location.reload();
        }
      })
      .catch((error) => {
        alert(error)
      });
  };
  return (
    <div className="my-course">
      <ul className="breadcrumbs">
        <li>
          <Link to="/teacher/courses">Курсы</Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <Link to={`/teacher/course_${location?.state?.courseId}`}>
            {location?.state?.courseName}
          </Link>
        </li>
        <li>
          <span>
            {location?.state?.courseId && (
              <i className="fa-solid fa-arrow-right"></i>
            )}
          </span>
        </li>
        <li>
          <span>{lesson.name}</span>
        </li>
      </ul>
      <h1 className="title">
         <span>Создание домашнего задания</span> 
      </h1>
      <form onSubmit={onSubmit} className="add-dz box">
        <div className="form-row form-row--main">
          <label><b>Вопрос:</b></label>
          <input name="display_name" onChange={onChange} type="text"></input>
        </div>
        {
          lesson?.lesson_type == "control" ? 
          <div className="form-row form-row--vyborka">
            <label>Тип вопроса:</label>
            <select onChange={selectChange} name="question_type">
              <option value="choice">Выборка</option>
              <option value="text_area">Тексарея</option>
            </select>
          </div> : ""
        }
        <div
            className={
              question.question_type == QuestionType.choice
                ? "form-row__choice active"
                : "form-row__choice"
            }
          >
          <div className="form-choice__row">
            <label>Значение 1:</label>
            <input
              name="value1"
              onChange={onChangeChoice}
              type="text"
            ></input>
            <div>
              <input
                onChange={onChangeCorrect}
                id="value1"
                type="radio"
                name="correct"
              ></input>
            </div>
          </div>
          <div className="form-choice__row">
            <label>Значение 2:</label>
            <input
              name="value2"
              onChange={onChangeChoice}
              type="text"
            ></input>
            <div>
              <input
                onChange={onChangeCorrect}
                type="radio"
                id="value2"
                name="correct"
              ></input>
            </div>
          </div>
          <div className="form-choice__row">
            <label>Значение 3:</label>
            <input
              name="value3"
              onChange={onChangeChoice}
              type="text"
            ></input>
            <div>
              <input
                onChange={onChangeCorrect}
                type="radio"
                id="value3"
                name="correct"
              ></input>
            </div>
          </div>
          <div className="form-choice__row">
            <label>Значение 4:</label>
            <input
              name="value4"
              onChange={onChangeChoice}
              type="text"
            ></input>
            <div>
              <input
                onChange={onChangeCorrect}
                type="radio"
                id="value4"
                name="correct"
              ></input>
            </div>
          </div>
        </div>
        <button className="btn btn-primary" type="submit">
          Добавить
        </button>
      </form>
      <div className="admin-question-flex">
        <h1 className="admin-title">Cписок вопросов</h1>
        <div className="btn-area">
          <button onClick={()=> setModalActive(true)} className="btn btn-inline btn-white">Скопировать вопросы с дз 
            <i className="fa-solid fa-plus"></i>
          </button>
          <ModalHomework active={modalActive} lessonid={lesson_id} setActive={setModalActive}/>
        </div>
      </div>
      <ul className="qadmin-list">
        {questions?.map((question: any, index: any) => (
          <li key={question.id}>
            <div className="question-admin">
              <div className="question-admin__heading">
                <div className="question-admin__text">
                  <div>
                    {index + 1 + ")"}
                    <input
                      value={question.display_name}
                      onChange={(e) => {
                        const newQuestions = [...questions];
                        newQuestions[index].display_name = e.target.value;
                        setQuestions(newQuestions);
                      }}
                      onBlur={() => {
                        if (
                          prevQuestions?.[index]?.display_name !==
                          questions?.[index]?.display_name
                        ) {
                          onUpdateQuestion(question);
                        }
                      }}
                      type="text"
                    />
                  </div>
                  <div className="question-admin__file">
                    <i className="fa-regular fa-image"></i>
                    <input
                      id={"" + question.id}
                      onChange={UploadQuestionPhoto}
                      accept="image/png, image/jpeg"
                      type="file"
                    ></input>
                  </div>
                </div>
                {question.image !== null ? (
                  <div className="realadmin-choice-row__img">
                    <img
                      src={"https://app.easyent.kz" + question.image}
                    ></img>
                  </div>
                ) : (
                  ""
                )}
              </div>
              {question["question_type"] &&
              question["question_type"] == QuestionType.choice ? (
                <div className="question-row__area">
                  {question["question_choise"] !== undefined &&
                  question["question_choise"]["values"] !== undefined &&
                  question["question_choise"]["values"].length > 0 ? (
                    question["question_choise"]["values"]
                      .sort((a: any, b: any) => a?.seq_idx - b?.seq_idx)
                      .map((choice: any, choiceIdx: any) => (
                        <div key={choice.id} className="admin-choice-row">
                          <div className="admin-choice">
                            {question.answer == choice.value ? (
                              <div className="tipo-input">
                                <i className="fa-solid fa-check"></i>
                              </div>
                            ) : (
                              <div
                                onClick={onChangeChoiceValue}
                                data-sequence={index + 1}
                                data-value={choice.value}
                                data-quesiton-id={"" + question.id}
                                className="tipo-input"
                              ></div>
                            )}
                            <div className="admin-choise__right">
                              <label htmlFor={"" + choice.id}>
                                <input
                                  value={choice.value}
                                  onChange={(e) => {
                                    const newQuestions = [...questions];
                                    newQuestions[index].question_choise.values[
                                      choiceIdx
                                    ].value = e.target.value;
                                    setQuestions(newQuestions);
                                  }}
                                  onBlur={() => {
                                    if (
                                      prevQuestions?.[index]?.question_choise
                                        ?.values[choiceIdx]?.value !==
                                      questions?.[index]?.question_choise
                                        ?.values[choiceIdx]?.value
                                    ) {
                                      onUpdateChoice(choice);
                                    }
                                  }}
                                  type="text"
                                />
                              </label>
                              <div className="question-admin__file">
                                <i className="fa-regular fa-image"></i>
                                <input
                                  id={"" + choice.id}
                                  onChange={UploadPhoto}
                                  accept="image/png, image/jpeg"
                                  type="file"
                                ></input>
                              </div>
                            </div>
                          </div>
                          {choice.image !== null ? (
                            <div className="adminreal-choice-row__img">
                              <img
                                src={"https://app.easyent.kz/" + choice.image}
                              ></img>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      ))
                  ) : (
                    <div>пусто</div>
                  )}
                </div>
              ) : (
                <div className="task-area">
                  {/* <div className="task-area__input">
                    <input type="file" name={"file"+question.id} id={"file"+question.id} onChange={UploadPhoto} accept="image/png, image/jpeg"></input>
                  </div>
                  <textarea placeholder="Написать ответ"  className="task-area__textarea"></textarea> */}
                </div>
              )}
              <div className="admin-choice-row">
                <div className="admin-choice-row__solution">
                  <label>
                    Загрузить решение:  
                  </label> 
                  <input
                    id={"" + question.id}
                    onChange={UploadSolutionPhoto}
                    accept="image/png, image/jpeg"
                    type="file"
                  >
                  </input>
                </div>
                {question.solution_image ? 
                  (
                    <div className="realadmin-choice-row__img">
                      <img src={"https://app.easyent.kz/" + question.solution_image}></img>
                    </div>
                  ) : ""
                }
              </div>
              <button className="btn btn-primary btn-delete" data-quesiton-id={"" + question.id} onClick={DeleteQuestion}>Удалить вопрос</button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};
