import axios from "axios";
import React, { useState, FC } from "react";
import { authHeader } from "src/utils/authHeader";
import Select from 'react-select';
import { ICourse } from "src/interface";

export const ModalGeneratePenalty: FC<{ active: boolean; setActive: (value: boolean) => void; courses: ICourse[] }> = 
  ({ active, setActive, courses }) => {
    const [selectedCourse, setSelectedCourse] = useState<number | null>(null);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [userId, setUserId] = useState<string | null>(null);

    const formatDate = (datetime: string) => {
      return datetime.split('T')[0]; // Extract the date part (YYYY-MM-DD)
    };

    const generatePenalty = () => {
      const formattedStartDate = formatDate(startDate);
      const formattedEndDate = formatDate(endDate);

      const url = `/api/v1/penalty/by_admin?start_date=${formattedStartDate}&end_date=${formattedEndDate}` +
                  (selectedCourse ? `&course_id=${selectedCourse}` : '') +
                  (userId ? `&user_id=${userId}` : '');

      axios
        .post(url, {}, { headers: authHeader() })
        .then((response) => {
          if (response.status === 200) {
            alert('Пенальи cгенерирован');
          }
        })
        .catch((error) => {
          console.error("Error generating penalty:", error);
          alert('Ошибка при генерации пенальти');
        });
    };

    return (
      <div className={active ? "modal active" : "modal"} onClick={() => setActive(false)}>
        <div className="modal__content" onClick={(e) => e.stopPropagation()}>
          <div className="modal__heading">Генерация </div>
          <div className="modal-row">
            <Select
              options={[
                { value: null, label: "Все курсы" },
                ...courses.map((course) => ({
                  value: course.id,
                  label: course.name,
                })),
              ]}
              onChange={(option: any) => setSelectedCourse(option ? option.value : null)}
              isClearable
            />
          </div>
          <div className="modal-row">
            <label>Начало:</label>
            <input
              className="modal-row__input"
              required
              name="start_date"
              type="date" // Changed to "date" to only allow selecting a date
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="modal-row">
            <label>Конец:</label>
            <input
              className="modal-row__input"
              required
              name="end_date"
              type="date" // Changed to "date" to only allow selecting a date
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className="modal-row">
            <label>User ID (опционально):</label>
            <input
              className="modal-row__input"
              name="user_id"
              type="text"
              value={userId || ''}
              onChange={(e) => setUserId(e.target.value || null)}
              placeholder="Введите User ID (если нужно)"
            />
          </div>
          <div onClick={() => generatePenalty()} className="btn btn-primary">Выполнить</div>
        </div>
      </div>
    );
  };
