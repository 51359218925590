import React, { FC} from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { ICourse} from "src/interface";
import "./MyCourseItem.scss";

export const MyCourseItem: FC<{ course: ICourse; showLessons?: boolean }> = ({
  course,
  showLessons,
}) => {
  if (showLessons) {
    document.title = `${course.name} | EasyENT`;
  }
  // const [setProgress] = useState<number>(0);
  const whatButtonToShow =
    showLessons === true ? (
      <div className="course-bot">
        <a
          target="_blank"
          rel="noreferrer"
          href={course.tg_chat_id}
          className="btn btn-primary btn-telegram btn-flex"
        >
          Телеграм чат с кураторами
          <img src="assets/svg/telegram.svg" />
        </a>
        <Link to={`/course_${course.id}`} className="btn btn-primary btn-flex">
          Аналитика ошибок <i className="fa-solid fa-angle-right"></i>
        </Link>
      </div>
    ) : (
      <Link to={`/course_${course.id}`} className="btn btn-primary btn-flex">
        Подробнее о курсе<i className="fa-solid fa-angle-right"></i>
      </Link>
    );

  return (
    <>
      <div className="course-item course-item--inner box">
        <Link to={`/course_${course.id}`} className="image-wrapper">
          <img src={course.photo_url} />
        </Link>
        <div className="course-wrapper">
          <span className="course-wrapper__title">{course.name}</span>
          <div className="course-details">
            <div>
              <span>
                <i className="fa-solid fa-user"></i>
              </span>
              {course.teacher ? course.teacher.name : ""}
            </div>
          </div>
          {/* <ProgressBar completed={progress} /> */}
          {whatButtonToShow}
        </div>
        
      </div>
    </>
  );
};

export const CourseItemSkeleton = () => {
  return (
    <div className="course-item">
      <div className="image-wrapper">
        <Skeleton width={300} height={200} />
        <div className="play-btn">
          <i className="fa-solid fa-play"></i>
        </div>
      </div>
      <div className="course-wrapper">
        <h2>
          <Skeleton width={338} height={32} />
        </h2>
        <p>
          <Skeleton width={200} height={32} />
        </p>
        <div className="course-details">
          <Skeleton width={170} height={17} />
          <Skeleton width={150} height={17} />
          <Skeleton width={170} height={17} />
          <Skeleton width={150} height={17} />
        </div>
        <Skeleton width={200} height={50} />
      </div>
    </div>
  );
};
