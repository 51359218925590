import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./AdminTestsPage.scss";
import { authHeader } from "src/utils/authHeader";

export const AdminTestsPage = () => {
  document.title = "Тесты | EasyENT";
  const [tests, setTests] = useState<any>([]);
  const [filteredTests, setFilteredTests] = useState<any>([]);
  const [year, setYear] = useState<string>(""); // State for year filter
  const [month, setMonth] = useState<string>(""); // State for month filter
  const [subjectId, setSubjectId] = useState<string>(""); 
  useEffect(() => {
    axios
      .get("/api/v1/tests/tests/?skip=0&limit=150", { headers: authHeader() })
      .then((response) => {
        setTests(response.data);
        setFilteredTests(response.data); // Initialize filtered tests
      });
  }, []);

  // Function to handle filtering
  const handleFilter = () => {
    const filtered = tests.filter((test: any) => {
      const testDate = new Date(test.write_date);
      const testYear = testDate.getFullYear().toString();
      const testMonth = (testDate.getMonth() + 1).toString().padStart(2, "0"); // Month as two digits
      return (
        (year ? testYear === year : true) &&
        (month ? testMonth === month : true) &&
        (subjectId ? test.subject.id.toString() === subjectId : true)
      );
    });
    setFilteredTests(filtered);
  };
  const subjects = [
    { id: 1, title: "История Казахстана" }, 
    { id: 5, title: "Мат.Грамотность" },
    { id: 10, title: "Математика" },
    { id: 23, title: "Физика" },
    { id: 29, title: "Химия" },
    { id: 48, title: "Биология" },
    { id: 91, title: "География" },
    { id: 110, title: "Английский язык" },
    { id: 100, title: "Всемирная история" },
    { id: 121, title: "Информатика" },
  ];
  // Filter tests whenever year or month changes
  useEffect(() => {
    handleFilter();
  }, [year, month, subjectId]);

  return (
    <div className="my-course">
      <h1 className="title">Тесты</h1>

      {/* Filter Controls */}
      <div className="filter-controls">
        <select value={year} onChange={(e) => setYear(e.target.value)}>
          <option value="">Select Year</option>
          <option value="2024">2024</option>
          <option value="2023">2023</option>
          <option value="2022">2022</option>
        </select>
        <select value={month} onChange={(e) => setMonth(e.target.value)}>
          <option value="">Select Month</option>
          <option value="01">January</option>
          <option value="02">February</option>
          <option value="03">March</option>
          <option value="04">April</option>
          <option value="05">May</option>
          <option value="06">June</option>
          <option value="07">July</option>
          <option value="08">August</option>
          <option value="09">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>
        <select value={subjectId} onChange={(e) => setSubjectId(e.target.value)}>
          <option value="">Select Subject</option>
          {subjects.map((subject) => (
            <option key={subject.id} value={subject.id}>
              {subject.title}
            </option>
          ))}
        </select>
      </div>

      <div className="admintop-buttons">
        <Link className="btn btn-primary" to="add">Добавить тест +</Link>
      </div>  

      {/* Table displaying filtered tests */}
      <table className="table">
        <tbody>
          <tr>
            <th>Id</th>
            <th>Предмет</th>
            <th>Язык</th>
            <th>Дата</th>
            <th>Управление</th>
          </tr>
          {filteredTests.map((test: any) => (
            <tr key={test.id}>
              <td>{test.id}</td>
              <td>{test.subject.title}</td>
              <td>{test.lang}</td>
              <td>{test.write_date}</td>
              <td>
                <Link className="btn btn-primary btn-flex" to={"/admin/tests/" + test.id}>
                  <i className="fa-solid fa-pen"></i>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
