import axios from "axios";
import React, { FC, useEffect, useState } from "react";
import { authHeader } from "src/utils/authHeader";
import { AdminUser} from "src/interface";
import "./ModalUserShow.scss";

export const ModalUserShow: FC<{active:boolean,setActive:(value:boolean) => void, user_id:number}> = 
  ({active, setActive, user_id}) => {
    const [user, setUser] = useState<AdminUser>();  
    useEffect(() => {
      axios
        .get("/api/v1/users/" + user_id + "/", { headers: authHeader() })
        .then(
          (response) => (
            setUser(response.data)
          )
        );
    }, []);
  return (
    <div className={active ? "modal-photo active" : "modal-photo"} onClick={()=> setActive(false)}>
      <div className="modal-content" onClick={e => e.stopPropagation()}>
        <h1 className="modal-content__heading">Обновить пользователя</h1>
        {user ? (
          <ul className="admin-user-ul">
            <li>
              <span>ФИО:</span> {user.full_name}
            </li>
            <li>
              <span>Номер:</span> {user.phone}
            </li>
            <li>
              <span>Email:</span> {user.email}
            </li>
            <li>
              <span>Телеграм:</span> {user.zoom_account}
            </li>
          </ul>
        ) : "" }
      </div>
    </div>
  )
}
