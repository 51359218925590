import axios from "axios";
import React, { useEffect, useState } from "react";
// import { useAppSelector } from "src/hooks/redux";
import { authHeader } from "src/utils/authHeader";
import { toast } from "react-toastify";
import {
  ICourse,
  BuyOrder,
} from "src/interface";
import "./BuyCoursesPage.scss";
import { useLocation } from "react-router-dom";
interface totalSum {
  totalSum: number;
  withoutSale: number;
  discount: number;
}


export const BuyCoursesPage = () => {
  document.title = "Покупка курсов | EasyENT";
  const location = useLocation();
  const [referral, setReferral] = useState<string | null>(null);
  const [coursesList, setCoursesList] = useState<ICourse[]>([]);
  const [totalSum, setTotalSum] = useState<totalSum>({
    totalSum: 0,
    withoutSale: 0,
    discount: 0,
  });
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const referralParam = queryParams.get("referal");
    if (referralParam) {
      localStorage.setItem("referal", referralParam);
      setReferral(referralParam);
    } else {
      const savedReferral = localStorage.getItem("referal");
      setReferral(savedReferral);
    }
  }, [location.search]);
  const [order, setOrder] = useState<BuyOrder>(
    {
      start_date: "2024-09-09",
      end_date: "2024-10-09",
      is_full: false,
      package: "standard",
      pricelist_id: 3,    
      status: "draft",
      positions: [],
      referral_code: referral,
    }
  );
  const GotoOrder = () => {
    const element = document.getElementById("buy-order");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    window.setTimeout(() => {
      window.scrollBy({
        top: 200, // Adjust this value to scroll further down
        behavior: "smooth",
      });
    }, 500);
  };
  useEffect(() => {
    setOrder((prevState: any) => ({
      ...prevState,
      referral_code: referral,
    }));
  }, [referral]);

  const [createdOrder, setcreatedOrder] = useState<any>({});
  const [sended, setSended] = useState(false);
  useEffect(() => {
    axios
      .get("/api/v1/courses?lang=ru&active=true", { headers: authHeader() })
      .then((response) => setCoursesList(response.data));
  }, []);
  
  useEffect(() => {
    calculateTotal();
    setcreatedOrder(null);
  }, [order]);

  const addCourse = (e: React.MouseEvent<Element>) => {
    const element = e.currentTarget;
    const position = {
      course_id: parseInt("" + element.getAttribute("data-course-id")),
      start_date: "2024-09-09",
      end_date: "2024-10-09",
    }
    setOrder((prevState: BuyOrder) => ({
      ...prevState,
      positions: [...prevState.positions, position], // Add new position to the array
    }));
  }  
  const calculateTotal = () => {
    if(order.positions.length == 0){
      setTotalSum({
        totalSum: 0,
        withoutSale: 0, 
        discount: 0,   
      });
    }
    else if(order.positions.length == 1){
      if(order.package == 'standard'){
        setTotalSum({
          totalSum: 12900,
          withoutSale: 14400, 
          discount: 1500,   
        });
      }
      else{
        setTotalSum({
          totalSum: 15780,
          withoutSale: 17280, 
          discount: 1500,  
        });
      }      
    }
    else if(order.positions.length == 2){
      if(order.package == 'standard'){
        setTotalSum({
          totalSum: 22920,
          withoutSale: 25920, 
          discount: 3000,  
        });
      }
      else{
        setTotalSum({
          totalSum: 28304,
          withoutSale: 31304, 
          discount: 3000,  
        });
      }  
    }
    else if(order.positions.length == 3){
      if(order.package == 'standard'){
        setTotalSum({
          totalSum: 33500,
          withoutSale: 38000, 
          discount: 4500,  
        });
      }
      else{
        setTotalSum({
          totalSum: 42600,
          withoutSale: 45600, 
          discount: 4500,  
        });
      }  
    }
    else{
      if(order.package == 'standard'){
        setTotalSum({
          totalSum: 42000,
          withoutSale: 48000, 
          discount: 6000,  
        });
      }
      else{
        setTotalSum({
          totalSum: 51600,
          withoutSale: 57600, 
          discount: 6000,  
        });
      }  
    }
  }
  const removeCourse = (course_id: number) => {
    setOrder((prevState: BuyOrder) => {
      // Correctly filter the positions array inside the order object
      const updatedPositions = prevState.positions.filter(
        (pos:any) => pos.course_id !== course_id
      );
  
      // Return the updated order object with the filtered positions
      return {
        ...prevState,
        positions: updatedPositions,
      };
    });
  };
  const CreateOrder = () => {
    if(sended != true){
      axios
      .post(`/api/v1/order/`, order, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          console.log(response);
          setcreatedOrder(response.data);
          toast.success("Платежка создана!");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.detail[1]);
      }).finally(()=>{
        setSended(true);
      });
    }
    else {
      toast.warn("Нельзя заново отправлять одно и тоже!");
    }
  }
  return (
    <>
     <h1 className="title title--small">Покупка курсов</h1>
      <ul className="ref-top-ul">
        <li>
          Код рефералки: {referral || "Рефералки нету"}
        </li>
      </ul>
      <div className="box buy-part">
        <div className="buy-step">
          <span className="buy-step__heading">Шаг 1: Выберите предметы</span>
          <ul className="buy-list">
            {coursesList.length ? coursesList.map((course) => (
              <li key={course.id}>
                {
                  order.positions.length > 0 &&
                    order.positions.filter(
                      (e:any) =>
                        e.course_id === course.id
                    ).length > 0 ? (
                  <div
                  onClick={() => (
                    removeCourse(course.id)
                  )}
                  className="buy-item buy-item--active">
                    <div className="buy-item__checkbox">
                      <i className="fa-solid fa-check"></i>
                    </div>
                    <span>{course.subject.title}</span>
                  </div> )
                  :  <div 
                  onClick={(event) => (
                    addCourse(event)
                  )}
                  data-course-id={course.id}
                  className="buy-item">
                    <div className="buy-item__checkbox"></div>
                    <span>{course.subject.title}</span>
                  </div>
                }
              </li>  
            )) : ""}
          </ul>
        </div>
        <div className="buy-step">
          <span className="buy-step__heading">Шаг 2: Выберите тариф</span>
          <ul className="buy-list">
            <li>
              {order.package == "standard" ? (
              <div className="buy-item buy-item--active">
                <div className="buy-item__checkbox">
                    <i className="fa-solid fa-check"></i>
                </div>
                <span>Про</span>
              </div> )
                : (
                <div 
                onClick={() => {
                  setOrder((prevState) => ({
                    ...prevState,
                    package: 'standard',
                    pricelist_id: 3
                  }));
                }} 
                className="buy-item">
                  <div className="buy-item__checkbox"></div>
                  <span>Про</span>
                </div> )
              }
            </li>
            <li>
            {order.package == "premium" ? (
              <div className="buy-item buy-item--active">
                <div className="buy-item__checkbox">
                    <i className="fa-solid fa-check"></i>
                </div>
                <span>Премиум</span>
              </div> )
                : (
                <div 
                onClick={() => {
                  setOrder((prevState) => ({
                    ...prevState,
                    package: 'premium',
                    pricelist_id: 4
                  }));
                }} 
                className="buy-item">
                  <div className="buy-item__checkbox"></div>
                  <span>Премиум</span>
                </div> )
              }
            </li>
          </ul>
        </div>
        <div className="buy-step">
          <span className="buy-step__heading">Шаг 3: Способ оплаты</span>
          <ul className="buy-list">
            <li>
              {order.is_full == false ? (
              <div className="buy-item">
                <div className="buy-item__checkbox">
                  <i className="fa-solid fa-check"></i>
                </div>
                <span>Месяц</span>
              </div> )
              : (
                <div className="buy-item">
                  <div className="buy-item__checkbox"></div>
                  <span>Месяц</span>
                </div> )
              }  
            </li>
          </ul>
        </div>
        <div className="buy-end">
          <div className="buy-end__info"> 
            <div>
              <span>Общая сумма:</span> 
              {totalSum.totalSum} тг
            </div>
            <div>
              <span>Без Скидки:</span> 
              <p className="cross">{totalSum.withoutSale}</p> тг
            </div>
            <div>
              <span>Скидка:</span> 
              {totalSum.discount} тг
            </div>
          </div>
          <button 
          onClick={() => {
            CreateOrder()
            GotoOrder()
            }
          }
          className="btn btn-primary btn-flex">Создать платежку</button>
        </div>
      </div>
      <div id="buy-order"> 
        {createdOrder !== null ? 
          (<div className="box buy-part">
            <span className="buy-part__heading">Проверьте платеж</span>
            <div className="payment-row">  
              <p className="payment-row__title">Номер заказа</p>
              <p>{createdOrder ? createdOrder.id : "нету"}</p>
            </div>
            <div className="payment-row">  
              <p className="payment-row__title">Мои курсы</p>
              <ul>
                {order.positions.map((item: any) => {
                  // Find the course that matches the course_id from positions
                  const course = coursesList.find((course) => course.id === item.course_id);
                  return (
                    <li key={item.course_id}>
                      {course ? course.subject.title : "Course not found"}
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="payment-row">  
              <p className="payment-row__title">Стоимость</p>
              <p>{createdOrder.total_amount }</p>
            </div>
            <form target="_blank" method="post" action="https://kaspi.kz/online" id="formOnlinePayment">
                <input type="hidden" name="TranId" value={createdOrder.id + ""}/>
                <input type="hidden" name="OrderId" value={createdOrder.id + ""}/>
                <input type="hidden" name="Amount" value={(createdOrder.total_amount * 100) + ""}/>
                <input type="hidden" name="Service" value="StayStudy"/>
                <input type="hidden" name="ReturnUrl" value={"https://app.easyent.kz/transaction/success?id="+createdOrder.id}/>
                <button className="btn btn-primary btn-flex">Оплатить через Kaspi</button>
            </form>
          </div>) :
          ""
        }
      </div>
      
    </>
  );
};
