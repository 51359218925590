import axios from "axios";
import {useState, FC } from "react";
import { authHeader } from "src/utils/authHeader";
import "./ModalTelegram.scss";
export const ModalTelegram: FC<{active:boolean; setActive:(value:boolean) => void;  }> = 
  ({active, setActive}) => {
    const [telegramAccount, setTelegramAccount] = useState<string>("");
    const submitTelegramAccount = () => {
      if(telegramAccount){
        axios
        .put("/api/v1/users/me", {
          zoom_account: telegramAccount
        }, {
          headers: authHeader(),
        })
        .then((response) => {
          if (response.status == 200) {
            setActive(false);
          }
        })
      }
    }
    
  return (
    <div className={active ? "modal active" : "modal"} onClick={()=> setActive(false)}>
      <div className="modal__content modal__content--telegram" onClick={e => e.stopPropagation()}>
        <div className="modal-info">
          <span className="modal-content__heading">Требуется имя пользователя</span>
          <p>Требуется @имя пользователя от телеграм, чтобы наши кураторы могли с вами связаться</p>
          <input onChange={(e)=> setTelegramAccount(e.target.value)} 
           placeholder="телеграм @пользователь" value={telegramAccount} type="text"/>
           <p className="modal-info__inst">Инструкция, если не знаете как найти</p>
          <button className="my-btn" onClick={()=> submitTelegramAccount()}>Отправить</button>
        </div>
        
        {/* <button onClick={()=> setActive(false)} className="my-btn">
          Закрыть окно
          <i className="fa-solid fa-xmark"></i>
        </button>  */}
      </div>
    </div>
  )
}
