import axios from "axios";
import React, { useEffect, useState } from "react";
import { ICourse, ILesson, AddLesson, lessonTypeOption } from "src/interface";
import { useParams, Link } from "react-router-dom";
import { authHeader } from "src/utils/authHeader";
import moment from "moment";
import "moment/locale/ru";
import Select from "react-select";
moment.locale("ru");
import "./AdminEditCourse.scss";

export const AdminEditCourse = () => {
  document.title = "Правка курса | EasyENT";
  const params = useParams();
  const [course, setCourse] = useState<ICourse>();
  const [lessons, setLessons] = useState<ILesson[]>([]);
  const getLessonDay = (day: Date) => {
    const lessonDay = moment(day).format("ll");
    return lessonDay;
  };
  const [lessonType, setLessonType] = useState({
    value: "lesson",
    label: "Урок",
  });
  // const today = moment();
  useEffect(() => {
    axios
      .get("/api/v1/courses/" + params.id, { headers: authHeader() })
      .then((response) => {
        setCourse(response.data);
      });
    axios
      .get("/api/v1/courses/" + params.id + "/lessons/", {
        headers: authHeader(),
      })
      .then((response) => {
        setLessons(response.data);
      });
  }, []);

  let course_id = 0;
  if (params.id) {
    course_id = parseInt(params.id);
  }
  const lessontypes: lessonTypeOption[] = [
    { value: "lesson", homework_visible: false, label: "Урок" },
    { value: "control", homework_visible: true, label: "Контрольная работы" },
  ];
  const [lesson, setLesson] = useState<AddLesson>({
    course_id: course_id,
    name: "",
    start_date: "",
    end_date: "",
    lesson_type: "lesson",
    time_code: [],
    homework_visible: true,
    lesson_number: 0,
    questions: [],
  });
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const element = e.target as HTMLInputElement;
    const value = element.value;
    const name = element.name;
    setLesson((s) => ({ ...s, [name]: value }));
  };

  let lessoncounter = 0;
  const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      lesson.name !== "" &&
      lesson.start_date !== "" &&
      lesson.end_date !== ""
    ) {
      axios
        .post("/api/v1/lessons/", lesson, { headers: authHeader() })
        .then((response) => {
          if (response.status == 200) {
            alert("Урок добавлен");
            window.location.reload();
          }
        })
        .catch((error) => {
          alert(error.response.data);
        });
    } else {
      alert("заполните все данные");
    }
  };

  function getDeadLineText(lesson: ILesson) {
    if (lesson?.end_date) {
      const endDate = moment(lesson.end_date);
      const deadLine = moment(endDate).add(3, "days");
      const todaysDate = moment();
      const daysDifference = deadLine.diff(todaysDate, "days");
      if (daysDifference < 0) {
        return ``;
      } else if (daysDifference === 0) {
        return "Сегодня последний день";
      } else if (daysDifference > 0 && daysDifference < 4) {
        return `Осталось ${Math.abs(daysDifference)} дн.`;
      } else {
        return ``;
      }
    }
  }

  return (
    <div>
      <ul className="breadcrumbs">
        <li>
          <Link to="/admin/courses">Мои курсы</Link>
        </li>
        <li>
          <span>
            <i className="fa-solid fa-arrow-right"></i>
          </span>
        </li>
        <li>
          <span>{course?.name}</span>
        </li>
      </ul>
      <h1 className="title">{course ? course.name : ""}</h1>
      <div className="box box--top">
        <form onSubmit={onSubmit}>
          <div className="les-row">
            <label className="les-row__name">Название урока:</label>
            <input
              onChange={onChange}
              required
              className="les-row__input"
              name="name"
              type="text"
            />
          </div>
          <div className="les-row">
            <label className="les-row__name">Даты:</label>
            <div className="les-row__date">
              <input
                onChange={onChange}
                required
                name="start_date"
                type="datetime-local"
              />
            </div>
            <div className="les-row__date">
              <input
                onChange={onChange}
                required
                name="end_date"
                type="datetime-local"
              />
            </div>
          </div>
          <div className="les-row">
            <label className="les-row__name">Номер урока:</label>
            <input
              onChange={onChange}
              required
              className="les-row__input"
              name="lesson_number"
              type="text"
            />
          </div>
          <div className="les-row">
            <label className="les-row__name">Тип урока:</label>
            <Select
              value={lessonType}
              options={lessontypes}
              onChange={(option: any) => {
               if ( option ) setLesson((oldprice) => ({
                      ...oldprice,
                      lesson_type: option.value,
                      homework_visible: option.homework,
                    }))
                 ;
                setLessonType(option);
              }}
            />
          </div>
          <button className="btn btn-primary" type="submit">
            Добавить урок
          </button>
        </form>
      </div>
      {lessons.length === 0 ? (
        <div className="box">Уроков нету</div>
      ) : (
        <div className="last-lesson">
          <>
            <span className="last-lesson__heading">Список уроков</span>
            {lessons.map((lesson) => {
             if( lesson.lesson_type == "control") lessoncounter += 1
               
              return (
                <div className="lesson-сard lesson-сard--admin" key={lesson.id}>
                  <div className="lesson-card__content">
                    <span className="lesson-card__date">
                      {lesson.start_date
                        ? getLessonDay(lesson.start_date)
                        : "Нету даты"}
                      {getDeadLineText(lesson) != "" ? (
                        <span className="lesson-card__deadline">
                          {getDeadLineText(lesson)}
                        </span>
                      ) : (
                        <></>
                      )}
                    </span>
                    <div className="lesson-card__heading">
                      {lesson.lesson_type == "control" ? (
                        lessoncounter
                      ) : (
                        <span>{lesson.lesson_number} Урок:</span>
                      )}{" "}
                      {lesson.name}
                    </div>
                    <div className="lesson-card__buttons">
                      <Link
                        className={
                          lesson.question_count && lesson.question_count > 0
                            ? "btn-work btn-work--full"
                            : "btn-work btn-empty"
                        }
                        to={`/admin/homework/${lesson.id}`}
                        state={{
                          courseId: course?.id,
                          courseName: course?.name,
                        }}
                      >
                        Домашнее задание{" "}
                        {lesson.question_count && lesson.question_count > 0 ? (
                          <i className="fa-solid fa-check"></i>
                        ) : (
                          <i className="fa-solid fa-minus"></i>
                        )}
                      </Link>
                      {lesson.video_url !== null && lesson.conspected !== null ?
                          <Link to={`/admin/update/${lesson.id}`} className="btn-work btn-work--full">
                            Обновить урок
                            <i className="fa-solid fa-check"></i>
                          </Link> 
                          :
                          <Link to={`/admin/update/${lesson.id}`} className="btn-work">
                            Обновить урок
                            <i className="fa-solid fa-angle-right"></i>
                          </Link>
                      } 
                      <Link
                        className="btn-work "
                        to={
                          course
                            ? `/admin/courses/course/${course.id}/homeworks_by_lesson/${lesson.id}`
                            : ""
                        }
                        state={{
                          courseId: course?.id,
                          courseName: course?.name,
                        }}
                      >
                        Дз от учеников
                        <i className="fa-solid fa-angle-right"></i>
                      </Link>
                      <Link
                        to={`/admin/view/${lesson.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn-work"
                      >
                        Просмотр урока
                        <i className="fa-solid fa-eye"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        </div>
      )}
    </div>
  );
};
