import axios from "axios";
import React, { useEffect, useState } from "react";
import { IPenalty, ICourse } from "src/interface";

import "./AdminPenalty.scss";
import Select from 'react-select';
import { authHeader } from "src/utils/authHeader";
import { ModalPenalty, ModalUserHomework, ModalGeneratePenalty } from "src/components";

export const AdminPenalty = () => {
  document.title = "Тесты | EasyENT";
  const [penalties, setPenalties] = useState<IPenalty[]>([]);
  const [courses, setCourses] = useState<ICourse[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<number | null>(null);
  const [userId, setUserId] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  // Separate modal states
  const [penaltyModalActive, setPenaltyModalActive] = useState(false);
  const [homeworkModalActive, setHomeworkModalActive] = useState(false);
  const [GeneratePenaltyModal, setGeneratePenaltyModal] = useState(false);

  // Penalty modal-specific state
  const [currentPenaltyId, setCurrentPenaltyId] = useState<number | null>(null);

  // Homework modal-specific state
  const [lessonNumber, setLessonNumber] = useState<number | null>(null);
  const [studentId, setStudentId] = useState<number | null>(null);

  useEffect(() => {
    axios
      .get("/api/v1/courses?lang=ru", { headers: authHeader() })
      .then((response) => setCourses(response.data));
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams();
    queryParams.append("skip", "0");
    queryParams.append("limit", "100");
    queryParams.append("last_month", "false");

    if (selectedCourse !== null) queryParams.append("course_id", String(selectedCourse));
    if (userId) queryParams.append("user_id", userId);
    if (startDate) queryParams.append("start_date", startDate);
    if (endDate) queryParams.append("end_date", endDate);

    const url = `/api/v1/penalty/?${queryParams.toString()}`;

    axios.get(url, { headers: authHeader() })
      .then((response) => setPenalties(response.data))
      .catch((error) => console.error("Error fetching penalties:", error));
  }, [selectedCourse, userId, startDate, endDate]);

  const findCourseName = (courseId: number) => {
    const course = courses.find((course) => course.id === courseId);
    return course ? course.name : "Unknown Course";
  };

  const openPenaltyModal = (penaltyId: number) => {
    setCurrentPenaltyId(penaltyId);
    setPenaltyModalActive(true);
  };

  const openHomeworkModal = (lessonId: number, studentId: number) => {
    setLessonNumber(lessonId);
    setStudentId(studentId);
    setHomeworkModalActive(true);
  };

  return (
    <div className="my-course">
      <div className="top-flex">
        <h1 className="title">Пенальти</h1>
        <div
          onClick={() => setGeneratePenaltyModal(true)}
          className="btn btn-primary btn-inline"
        >
          Генерация
        </div>
      </div>

      <div className="white-row box">
        <div className="white-div">
          <span>Курсы:</span>
          <Select
            options={[
              { value: null, label: "Все курсы" },
              ...courses.map((course) => ({
                value: course.id,
                label: course.name,
              })),
            ]}
            styles={{
              container: (provided) => ({
                ...provided,
                minWidth: "170px",
              }),
            }}
            onChange={(option: any) => setSelectedCourse(option ? option.value : null)}
            isClearable
          />
        </div>
        <div className="white-div">
          <span>User ID:</span>
          <input
            className="white-div__input" 
            type="text"
            value={userId || ""}
            onChange={(e) => setUserId(e.target.value || null)}
            placeholder="Введите User ID (опционально)"
          />
        </div>
        <div className="white-div">
          <span>Дата начала:</span>
          <input
            className="white-div__input"
            type="date"
            value={startDate || ""}
            onChange={(e) => setStartDate(e.target.value || null)}
          />
        </div>
        <div className="white-div">
          <span>Дата окончания:</span>
          <input
            className="white-div__input"
            type="date"
            value={endDate || ""}
            onChange={(e) => setEndDate(e.target.value || null)}
          />
        </div>
      </div>
      <table className="table">
        <tbody>
          <tr>
            <th style={{ width: "40px" }}>Id</th>
            <th style={{ width: "150px" }}>Курс</th>
            <th style={{ width: "90px" }}>Урок</th>
            <th style={{ width: "120px" }}>Статус</th>
            <th style={{ width: "80px" }}>Дата создания</th>
            <th style={{ width: "90px" }}>Пользователь</th>
          </tr>
          {penalties.map((penalty) => (
            <tr key={penalty.id}>
              <td style={{ width: "40px" }}>{penalty.id}</td>
              <td style={{ width: "150px" }}>{findCourseName(penalty.course_id)}</td>
              <td style={{ width: "90px" }}>
                <p>{penalty.lesson_id}</p>
                <div
                  onClick={() => openHomeworkModal(penalty.lesson_id, penalty.user_id)}
                  className="item-control__item item-control__item--change"
                >
                  Ссылка на дз
                </div>
              </td>
              <td style={{ width: "120px" }}>
                {penalty.cancel_reason ? (
                  ""
                ) : (
                  <>
                    <p>"Потеря жизни"</p>
                    <div
                      onClick={() => openPenaltyModal(penalty.id)}
                      className="item-control__item item-control__item--change"
                    >
                      Поменять
                    </div>
                  </>
                )}
              </td>
              <td style={{ width: "150px" }}>{(penalty.created_at + "").slice(0, 10)}</td>
              <td style={{ width: "90px" }}>
                {penalty.user ? penalty.user.full_name : ""}
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Penalty Modal */}
      {penaltyModalActive && currentPenaltyId !== null && (
        <ModalPenalty
          active={penaltyModalActive}
          penaltyid={currentPenaltyId}
          setActive={setPenaltyModalActive}
        />
      )}

      {/* Homework Modal */}
      {homeworkModalActive && lessonNumber && studentId !== null && (
        <ModalUserHomework
          active={homeworkModalActive}
          lessonNumber={lessonNumber}
          studentId={studentId}
          setActive={setHomeworkModalActive}
        />
      )}
      {GeneratePenaltyModal !== null && (
        <div>
          <ModalGeneratePenalty
            active={GeneratePenaltyModal}
            courses={courses}
            setActive={setGeneratePenaltyModal}
          />
        </div>
      )}
    </div>
  );
};
