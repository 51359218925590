import axios from "axios";
import React, { useEffect, useState } from "react";
import { ICourse } from "src/interface";
import { Link } from "react-router-dom";
import { authHeader } from "src/utils/authHeader";

export const TeacherСoursesPage = () => {
  document.title = "Курсы | EasyENT";
  const [allCourses, setAllCourses] = useState<ICourse[]>([]);
  const [coursesType, setCourseType] = useState<boolean>(true);
  useEffect(() => {
    axios
      .get("/api/v1/courses/my/?skip=0&limit=100&active=" + coursesType, { headers: authHeader()})
      .then((response) => setAllCourses(response.data));
  }, [coursesType]);
 
  return (
    <div className="my-course">
      <h1 className="title">Мои курсы</h1>
      <ul className="mycourse-list">
        <li>
          <div className={coursesType == true ? "course-link course-link--active" : "course-link"} onClick={
            () => setCourseType(true)
          }>Курсы 2025</div>
        </li>
          <div className={coursesType == false ? "course-link course-link--active" : "course-link"} onClick={
            () => setCourseType(false)
          }>Старые</div>
      </ul>
      <table className="table">
        <tbody>
        <tr>
          <th>
            Id
          </th>
          <th>
            Наименование
          </th>
          <th>
            Язык
          </th>
          <th>
            Преподаватель
          </th>
          <th>
            Управление
          </th>
        </tr>
        {allCourses.map((course) => (
          <tr key={course.id}>
            <td>
            {course.id}
            </td>
            <td>
            {course.name}
            </td>
            <td>
              {course.lang}
            </td>
            <td>
             {course.teacher ? course.teacher.name : ""}
            </td>
            <td>
              <div className="item-control">
                <Link to={`course_${course.id}`} className="btn btn-primary btn-flex"> 
                  <i className="fa-solid fa-pen"></i>
                </Link>
              </div>
            </td>
          </tr>
        ))}
        </tbody>
      </table>
    </div>
  );
};
